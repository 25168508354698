import axios from 'axios'
import config from './config'

const URL = `${config.URL}/ic`

export async function create (name, domain, objective) {
  const response = await axios.post(`${URL}/create`, { name, domain, objective }, config.configWithToken())
  return response.data
}

export async function filter (page) {
  const response = await axios.get(`${URL}/filter/${page}`, config.configWithToken())
  return response.data
}

export async function generateReport (number) {
  const response = await axios.post(`${URL}/generate/report`, { number }, config.configWithToken())
  return response.data
}

export default {
  create,
  filter,
  generateReport
}
