import axios from 'axios'
import config from './config'

const URL = `${config.URL}/element-information`

export async function create (data) {
  const result = await axios.post(`${URL}/create`, data, config.configWithToken())
  return result.data
}

export async function all () {
  const result = await axios.get(`${URL}/all`, config.configWithToken())
  return result.data
}

export default {
  create,
  all
}