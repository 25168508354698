import React from 'react'
import {
  Button,
  Modal,
  Row,
  Col
} from 'reactstrap'

const Delete = (props) => {
  return (
    <Row>
      <Col md='4'>
        <Modal
          className='modal-dialog-centered modal-danger'
          contentClassName='bg-gradient-danger'
          isOpen={props.open}
          toggle={() => props.setOpen(false)}
        >
          <div className='modal-header'>
            <button
              aria-label='Cerrar'
              className='close'
              data-dismiss='modal'
              type='button'
              onClick={() => props.setOpen(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="py-3 text-center">
              <i className="ni ni-bell-55 ni-3x" />
              <h4 className="heading mt-4">Borrar hallazgo!</h4>
              <p>
                Está seguro que quiere borrar el hallazgo # {props.code} ?
              </p>
            </div>
          </div>
          <div className='modal-footer'>
            <Button
              className='btn-white'
              color='default'
              type='button'
              onClick={() => props.deleteFinding(props.deleteID)}
            >
              Borrar hallazgo
            </Button>
            <Button
              className='text-white ml-auto'
              color='link'
              data-dismiss='modal'
              type='button'
              onClick={() => props.setOpen(false)}
            >
              Cerrar
            </Button>
          </div>
        </Modal>
      </Col>
    </Row>
  )
}

export default Delete
