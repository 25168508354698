import React from 'react'
import {
  Redirect,
  Link
} from 'react-router-dom'
import {
  Container,
  Button
} from 'reactstrap'
import FileBase64 from 'react-file-base64'
import Nav from '../../Nav'
import ImagePreview from '../../ImagePreview'
import API from '../../../api/index'

const Detail = (props) => {
  const [dnsOriginal, setDNSOrigial] = React.useState('')
  const [dnsOriginalMessage, setDNSOrigialMessage] = React.useState('')
  const [dnsChange, setDNSChange] = React.useState('')
  const [dnsChangeMessage, setDNSChangeMessage] = React.useState('')
  const [status, setStatus] = React.useState('')
  const [dateRemediation, setDateRemediation] = React.useState('')
  const [dateRemediationMessage, setDateRemediationMessage] = React.useState('')
  const [impact, setImpact] = React.useState('')
  const [recommendation, setRecommendation] = React.useState('')
  const [recommendationMessage, setRecommendationMessage] = React.useState('')
  const [messageSuccess, setMessageSuccess] = React.useState('')
  const [risk, setRisk] = React.useState(0)
  const [images, setImages] = React.useState([])

  const [comment, setComment] = React.useState('')

  const [record, setRecord] = React.useState({})
  const [redirect, setRedirect] = React.useState('')
  const [changeDNSStatus, setChangeDNSStatus] = React.useState([])
  const [profile, setProfile] = React.useState('client')
  const [openImage, setOpenImage] = React.useState(false)
  const [imagePreview, setImagePreview] = React.useState('')
  const [detectedBy, setDetectedBy] = React.useState('')
  const [evidences, setEvidences] = React.useState([])
  const [evidenceURL, setEvidenceURL] = React.useState('')
  const [evidenceDescription, setEvidenceDescription] = React.useState('')

  async function getProfile() {
    const data = await API.auth.verify()
    if (data.ok) {
      setProfile(data.profile)
    } else {
      setRedirect('/change-dns')
    }
  }

  async function getDetail() {
    const response = await API.dns.detail(props.match.params.id)
    const record = response.record
    if (response.ok) {
      setRecord(record)
      setDNSOrigial(record.dns_original)
      setDNSChange(record.dns_change)
      setDateRemediation(record.date_remediation)
      setImpact(record.impact)
      setRecommendation(record.recommendation)
      setRisk(record.risk)
      setDetectedBy(record.detected_by)
      if (record.evidences) {
        setEvidences(record.evidences)
      }
      if (record.change_dns_status) {
        setChangeDNSStatus(record.change_dns_status)
      }
    } else {
      setRedirect('/change-dns')
    }
  }

  async function onSubmit () {
    let data = {
      dns_original: dnsOriginal,
      dns_change: dnsChange,
      date_remediation: dateRemediation,
      recommendation: recommendation,
      impact: impact,
      risk: risk,
      screenshots: images,
      detected_by: detectedBy
    }
    if (evidenceURL && evidenceDescription) {
      data['evidences'] = [{
        'url': evidenceURL,
        'description': evidenceDescription
      }]
    }
    if (status) {
      data['change_dns_status'] = [{
        'status': status,
        'comment': comment
      }]
    }
    const response = await API.dns.update(props.match.params.id, data)
    if (response.ok) {
      getDetail()
      setMessageSuccess(response.message)
      setStatus('')
      setComment('')
      setImages([])
    }
  }

  React.useEffect(() => {
    getProfile()
  }, [])

  React.useEffect(() => {
    getDetail()
  }, [])

  function getImages(file) {
    if (file.type.split('/')[0] === 'image') {
      setImages([...images, {'name': file.name, 'base64': file.base64}])
    }
  }

  if (redirect) {
    return (
      <Redirect to={redirect} />
    )
  }

  return (
    <div className='layout'>
      <Nav item='changeDNS' />
      <div className='main-content'>
        <div className='header bg-gradient-info pb-8 pt-5 pt-md-6'>
          <Container fluid>
            <Button className='btn-icon btn-3' color='default' type='button'>
              <span className='btn-inner--icon'>
                <i className='ni ni-bold-left' />
              </span>
              <Link className='btn-inner--text text-white' to='/change-dns'>Volver</Link>
            </Button>
          </Container>
        </div>
        <Container className='mt--7' fluid>
          <div className='content-auto'>
            <div className='row'>
              <div className='col-xl-12 order-xl-1'>
                <div className='card bg-secondary shadow'>
                  <div className='card-header bg-white border-0'>
                    <div className='row align-items-center'>
                      <div className='col-8'>
                        <h3 className='mb-0'>Información del cambio de DNS</h3>
                      </div>
                    </div>
                  </div>
                  <div className='card-body'>
                    <form>
                      <h6 className='heading-small text-muted mb-4'>Información del cambio de DNS</h6>
                      <div className='pl-lg-4'>
                      <div className='row'>
                          <div className='col-lg-6'>
                            <div className='form-group'>
                              <label className='form-control-label'>Registro DNS original</label>
                              <textarea
                                className='form-control form-control-alternative'
                                rows='5'
                                name='dnsOriginal'
                                readOnly={(record.status==='cerrado' || profile === 'client') ? true: false}
                                value={dnsOriginal}
                                onChange={(event) => {
                                  setDNSOrigial(event.target.value)
                                  setDNSOrigialMessage('')
                                }}
                              />
                              {dnsOriginalMessage && (
                                <span className='badge badge-pill badge-danger'>{dnsOriginalMessage}</span>
                              )}
                            </div>
                          </div>
                          <div className='col-lg-6'>
                            <div className='form-group'>
                              <label className='form-control-label'>Registro DNS modificado</label>
                              <textarea
                                className='form-control form-control-alternative'
                                rows='5'
                                name='dnsChange'
                                value={dnsChange}
                                readOnly={(record.status==='cerrado' || profile === 'client') ? true: false}
                                onChange={(event) => {
                                  setDNSChange(event.target.value)
                                  setDNSChangeMessage('')
                                }}
                              />
                              {dnsChangeMessage && (
                                <span className='badge badge-pill badge-danger'>{dnsChangeMessage}</span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-lg-6'>
                            <div className='form-group'>
                              <label className='form-control-label'>Fecha de detección</label>
                              <input
                                type='date'
                                name='dateDetection'
                                className='form-control form-control-alternative'
                                readOnly
                                value={record.date_detection}
                              />
                            </div>
                          </div>
                          <div className='col-lg-6'>
                            <div className='form-group'>
                              <label className='form-control-label'>Fecha de mitigación</label>
                              <input
                                type='date'
                                name='dateRemediation'
                                className='form-control form-control-alternative'
                                value={dateRemediation}
                                readOnly={(record.status==='cerrado' || profile === 'client') ? true: false}
                                onChange={(event) => {
                                  setDateRemediation(event.target.value)
                                  setDateRemediationMessage('')
                                }}
                              />
                              {dateRemediationMessage && (
                                <span className='badge badge-pill badge-danger'>{dateRemediationMessage}</span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-lg-6'>
                            <div className='form-group'>
                              <label className='form-control-label'>Estado</label>
                              <select
                                className='form-control form-control-alternative'
                                name='status'
                                value={record.status}
                                readOnly
                              >
                                <option value=''>Seleccione un estado</option>
                                <option value='nuevo'>Nuevo</option>
                                <option value='triage'>Triage</option>
                                <option value='investigación'>Investigación</option>
                                <option value='contención'>Contención</option>
                                <option value='cerrado'>Cerrado</option>
                              </select>
                            </div>
                          </div>
                          <div className='col-lg-6'>
                            <div className='form-group'>
                              <label className='form-control-label'>Riesgo (%)</label>
                              <input
                                type='int'
                                name='risk'
                                className='form-control form-control-alternative'
                                readOnly={(record.status==='cerrado' || profile === 'client') ? true: false}
                                value={risk}
                                onChange={(event) => {
                                  const value = event.target.value
                                  if (value >= 0 && value <= 100) {
                                    setRisk(value)
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-lg-12'>
                            <div className='form-group'>
                              <label className='form-control-label'>Comentarios del analista</label>
                              <textarea
                                className='form-control form-control-alternative'
                                rows='5'
                                name='recommendation'
                                readOnly={(record.status==='cerrado' || profile === 'client') ? true: false}
                                value={recommendation}
                                onChange={(event) => {
                                  setRecommendation(event.target.value)
                                  setRecommendationMessage('')
                                }}
                              />
                              {recommendationMessage && (
                                <span className='badge badge-pill badge-danger'>{recommendationMessage}</span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-lg-6'>
                            <div className='form-group'>
                              <label className='form-control-label'>Detectado por</label>
                              <input
                                type='text'
                                name='detectedBy'
                                className='form-control form-control-alternative'
                                value={detectedBy}
                                readOnly={(record.status==='cerrado' || profile === 'client') ? true: false}
                                onChange={(event) => {
                                  setDetectedBy(event.target.value)
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='form-group'>
                            <label className='form-control-label'>Screenshots</label>
                            <div
                              className='form-control form-control-alternative'
                            >
                              <FileBase64 onDone={getImages} />
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          {images.map((image, index) => (
                            <div className='col-lg-3'>
                              <img src={image.base64} alt='finding' key={index} className='img-fluid' />
                            </div>
                          ))}
                        </div>
                        <h3>Evidencias</h3>
                        {evidences.map((evidence, index) => (
                          <div className='row' key={index}>
                            <div className='col-lg-6'>
                              <div className='form-group'>
                                <label className='form-control-label'>URL</label>
                                <input
                                  type='text'
                                  readOnly={true}
                                  className='form-control form-control-alternative'
                                  value={evidence.url}
                                />
                              </div>
                            </div>
                            <div className='col-lg-6'>
                              <div className='form-group'>
                                <label className='form-control-label'>Descripción</label>
                                <textarea
                                  className='form-control form-control-alternative'
                                  rows='5'
                                  readOnly={true}
                                  value={evidence.description}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className='row'>
                          <div className='col-lg-6'>
                            <div className='form-group'>
                              <label className='form-control-label'>URL</label>
                              <input
                                type='text'
                                name='evidenceURL'
                                className='form-control form-control-alternative'
                                placeholder='https://www.example.com'
                                value={evidenceURL}
                                onChange={(event) => {
                                  setEvidenceURL(event.target.value)
                                }}
                              />
                            </div>
                          </div>
                          <div className='col-lg-6'>
                            <div className='form-group'>
                              <label className='form-control-label'>Descripción</label>
                              <textarea
                                className='form-control form-control-alternative'
                                rows='5'
                                name='evidenceDescription'
                                value={evidenceDescription}
                                onChange={(event) => {
                                  setEvidenceDescription(event.target.value)
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {record.screenshots && (
                          <div className='row'>
                            {record.screenshots.map((screenshot, index) => (
                              <div className='col-lg-6' key={index}>
                                <img
                                  src={screenshot}
                                  key={index}
                                  className='img-fluid'
                                  alt='screenshot'
                                  onClick={() => {
                                    setOpenImage(true)
                                    setImagePreview(screenshot)
                                  }}
                                />
                              </div>
                            ))}
                            <ImagePreview
                              setOpen={setOpenImage}
                              open={openImage}
                              image={imagePreview}
                              name={'Suplantación'}
                            />
                          </div>
                        )}
                      </div>
                      {messageSuccess && (
                        <span className='badge badge-pill badge-success'>{messageSuccess}</span>
                      )}
                      {((record.status!=='cerrado' && profile === 'administrator')) && (
                        <div>
                          <hr className='my-4' />
                          <h6 className='heading-small text-muted mb-4'>Nueva novedad</h6>
                          <div className='pl-lg-4'>
                            <div className='row'>
                              <div className='col-lg-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Comentario</label>
                                  <textarea
                                    className='form-control form-control-alternative'
                                    rows='5'
                                    name='comment'
                                    value={comment}
                                    onChange={(event) => setComment(event.target.value)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-lg-6'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Estado</label>
                                  <select
                                    className='form-control form-control-alternative'
                                    name='status'
                                    value={status}
                                    onChange={(event) => setStatus(event.target.value)}
                                  >
                                    <option value=''>Seleccione un estado</option>
                                    <option value='nuevo'>Nuevo</option>
                                    <option value='triage'>Triage</option>
                                    <option value='investigación'>Investigación</option>
                                    <option value='contención'>Contención</option>
                                    <option value='cerrado'>Cerrado</option>
                                  </select>
                                </div>
                              </div>
                              <div className='col-lg-6'>
                                <div className='form-group'>
                                <label className='form-control-label text-white'>.</label>
                                  <button
                                    className='btn btn-icon btn-3 btn-primary form-control form-control-alternative'
                                    type='button'
                                    onClick={() => onSubmit()}
                                  >
                                    <span className='btn-inner--icon'><i className='ni ni-bag-17'></i></span>
                                    <span className='btn-inner--text'>Actualizar</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {changeDNSStatus.map((value, index) => (
                        <div key={index}>
                          <hr className='my-4' />
                          <h6 className='heading-small text-muted mb-4'>{value.date}</h6>
                          <div className='pl-lg-4'>
                            <div className='row'>
                              <div className='col-lg-6'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Estado</label>
                                  <select
                                    className='form-control form-control-alternative'
                                    readOnly
                                    value={value.status}
                                  >
                                    <option value='nuevo'>Nuevo</option>
                                    <option value='triage'>Triage</option>
                                    <option value='investigación'>Investigación</option>
                                    <option value='contención'>Contención</option>
                                    <option value='cerrado'>Cerrado</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-lg-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Comentario</label>
                                  <textarea
                                    className='form-control form-control-alternative'
                                    rows='5'
                                    readOnly
                                    value={value.comment}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
        </div>
    )
}

export default Detail
