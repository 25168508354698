import axios from 'axios'
import config from './config'
import { setItem } from './utils'

const URL = `${config.URL}/auth`

export async function login (email, password) {
  const result = await axios.post(`${URL}/login`, { email, password })
  if (result.data.token) {
    setItem('token2FA', result.data.token)
  }
  return result.data
}

export async function verify2fa (token, token_number) {
  const result = await axios.post(`${URL}/validate-2fa`, { token, token_number })
  if (result.data.token) {
    setItem('token', result.data.token)
    setItem('firstName', result.data.first_name)
    setItem('lastName', result.data.last_name)
  }
  return result.data
}

export async function resend_2fa (token) {
  const result = await axios.post(`${URL}/resend-2fa`, { token })
  return result.data
}

export async function verify () {
  const result = await axios.get(`${URL}/verify`, config.configWithToken())
  return result.data
}


export default {
  login,
  verify2fa,
  verify,
  resend_2fa
}
