import axios from 'axios'
import config from './config'

const URL = `${config.URL}/infrastructure-exposure`

export async function create (data) {
  const result = await axios.post(`${URL}/create`, data, config.configWithToken())
  return result.data
}

export async function filter (page) {
  const result = await axios.get(`${URL}/filter/${page}`, config.configWithToken())
  return result.data
}

export async function detail (id) {
  const result = await axios.get(`${URL}/detail/${id}`, config.configWithToken())
  return result.data
}

export async function update (id, data) {
  const result = await axios.post(`${URL}/update/${id}`, data, config.configWithToken())
  return result.data
}

export async function status () {
  const result = await axios.get(`${URL}/status`, config.configWithToken())
  return result.data
}

export async function deleteFinding (id) {
  const result = await axios.post(`${URL}/delete`, {id}, config.configWithToken())
  return result.data
}

export default {
  create,
  filter,
  detail,
  update,
  status,
  deleteFinding
}