import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import {
  Form,
  Row,
  Col,
  FormGroup,
  Button,
  Badge
} from 'reactstrap'
import Logo from '../assets/img/cattleya.png'
import API from '../api/index'
import '../assets/css/login.css'

class Login extends Component {
  constructor (props) {
    super(props)
    this.initialState = {
      email: '',
      password: '',
      error: false,
      messageError: '',
      redirect: false
    }
    this.state = this.initialState
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  updateState (key, value) {
    this.setState({
      [key]: value
    })
  }

  onChange (event) {
    this.updateState('messageError', '')
    this.updateState(event.target.name, event.target.value)
  }

  async onSubmit () {
    this.updateState('messageError', '')
    if (this.state.email && this.state.password) {
      const response = await API.auth.login(this.state.email.toLowerCase(), this.state.password)
      if (response.ok) {
        this.updateState('redirect', '/verify')
      } else {
        this.setState({
          'messageError': 'Usuario o contraseña incorrectos intentalo nuevamente'
        })
      }
    } else {
      this.setState({
        'messageError': 'Usuario y contraseña son obligatorios'
      })
    }
  }

  render () {
    if (this.state.redirect) {
      return (<Redirect to={this.state.redirect} />)
    }
    return (
      <div className='center-block-login container-login'>
        <div style={{ minWidth: '415px' }}>
          <div className='text-center'>
            <a href='/' className='login-title'>
              <img src={Logo} alt='Cattleya' width='200' />
            </a>
            <p className='gray'>Inicia sesión para continuar</p>
          </div>
          {this.state.messageError && (
            <div className='alert alert-danger' role='alert'>
              <strong>{this.state.messageError}</strong>
            </div>
          )}
          <div>
            <Form>
              <Row>
                <Col>
                  <FormGroup>
                    <div className='input-group input-group-alternative'>
                      <div className='input-group-prepend'>
                        <span className='input-group-text'>
                          <i className='ni ni-email-83' />
                        </span>
                      </div>
                      <input
                        className='form-control'
                        placeholder='Correo electrónico'
                        type='email'
                        name='email'
                        value={this.state.email}
                        onChange={this.onChange}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            this.onSubmit()
                          }
                        }}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <div className='input-group input-group-alternative'>
                      <div className='input-group-prepend'>
                        <span className='input-group-text'>
                          <i className='ni ni-lock-circle-open' />
                        </span>
                      </div>
                      <input
                        className='form-control'
                        placeholder='Contraseña'
                        type='password'
                        name='password'
                        onChange={this.onChange}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            this.onSubmit()
                          }
                        }}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p style={{color: '#BB2180', fontWeight: 600}}>
                    Te invitamos a probar el nuevo diseño de Cattleya
                    {' '}
                    <Badge
                      color='success'
                      style={{backgroundColor: '#1aae6f'}}
                    >
                      <a href='https://40239df4-8885-455f-b2cf-697efce9777fvya.cattleya.io'>
                        Ver ahora
                      </a>
                    </Badge>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button style={{
                      backgroundColor: '#BB2180',
                      borderColor: '#BB2180',
                      color: 'white'
                    }} size='md' type='button' onClick={this.onSubmit}>
                    Iniciar sesión
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    )
  }
}

export default Login
