import React from 'react'

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='row align-items-center justify-content-xl-between'>
        <div className='col-xl-12'>
          <div className='copyright text-center text-muted'>
          &copy; 2019 - Plataforma de inteligencia de amenazas y vigilancia tecnológica Cattleya ® - powered by <a href="https://www.csiete.org" className='font-weight-bold ml-1'>CSIETE</a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
