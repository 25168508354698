import React from 'react'
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table
} from 'reactstrap'

function ComponentTable (props) {
  const createField = (row) => {
    const columns = []
    for (let index = 0; index < props.columns.length; index++) {
      const column = props.columns[index]
      const value = row[column.key]
      if (column.type === 'text') {
        columns.push(<td onClick={() => props.handleClicRow(row.id)} key={index}>{value}</td>)
      } else if (column.type === 'status') {
        columns.push(<td onClick={() => props.handleClicRow(row.id)} key={index}>{value}</td>)
      } else if (column.type === 'risk') {
        columns.push(<td onClick={() => props.handleClicRow(row.id)} key={index}>{value}%</td>)
      }
    }
    if (props.action) {
      if (props.upload) {
        columns.push(<td key={500}>{props.action(row.id, row.code, row.url)}</td>)
      } else {
        columns.push(<td key={500}>{props.action(row.id, row.code)}</td>)
      }
    }
    return columns
  }

  return (
    <Card className='shadow'>
      <CardHeader className='border-0'>
        <h3 className='mb-0'>{props.name}</h3>
      </CardHeader>
      <Table className='align-items-center table-flush' responsive>
        <thead className='thead-light'>
          <tr>
            {props.columns.map((column, index) => (
              <th key={index} scope='col'>{column.name}</th>
            ))}
            {props.action && (
              <th scope='col'>Acción</th>
            )}
          </tr>
        </thead>
        <tbody>
          {props.rows.map((row, index) => {
            const column = createField(row)
            return <tr key={index}>{column}</tr>
          })}
        </tbody>
      </Table>
      <CardFooter className='py-4'>
        <nav>
          <Pagination
            className='pagination justify-content-end mb-0'
            listClassName='justify-content-end mb-0'
          >
            <PaginationItem>
              <PaginationLink onClick={props.previous}>
                <i className='fas fa-angle-left' />
                <span className='sr-only'>Anterior</span>
              </PaginationLink>
            </PaginationItem>
            <PaginationItem className='active'>
              <PaginationLink>
                {props.page}
              </PaginationLink> 
            </PaginationItem>
            <PaginationItem>
              <PaginationLink onClick={props.after} >
                <i className='fas fa-angle-right' />
                <span className='sr-only'>Siguiente</span>
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </nav>
      </CardFooter>
    </Card>
  )
}

export default ComponentTable
