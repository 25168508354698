import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap'

const ImagePreview = (props) => {

  React.useEffect(() => {
    document.addEventListener('keydown', () => props.setOpen(false), false)
  }, [])

  return (
    <Modal returnFocusAfterClose={() => props.setOpen(false)} isOpen={props.open} size='lg'>
      <ModalHeader toggle={() => props.setOpen(false)} charCode='[X]' />
      <ModalBody>
        <img src={props.image} alt={props.name} style={{maxWidth: '100%'}} />
      </ModalBody>
    </Modal>
  )
}

export default ImagePreview
