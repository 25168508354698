import auth from './auth'
import ic from './ic'
import user from './user'
import phishing from './phishing'
import leak from './leak'
import exposure from './exposure'
import dns from './dns'
import ssl from './ssl'
import notification from './notification'
import element from './element'
import upload from './upload'
import risk from './risk'

export default {
  auth,
  ic,
  user,
  phishing,
  leak,
  exposure,
  dns,
  ssl,
  notification,
  element,
  upload,
  risk
}
