import { getItem } from './utils'

const URL = 'https://api-tuya-b92193db.cattleya.io'

export const configWithToken = () => {
  return ({
    headers: {
      'Authorization': `Bearer ${getItem('token')}`,
      'contentType': 'application/json'
    }
  })
}

export default {
  configWithToken,
  URL
}
