import React from 'react'
import { Link } from 'react-router-dom'
import {
  Container,
  Button
} from 'reactstrap'
import Nav from '../../Nav'
import API from '../../../api/index'
import FileBase64 from 'react-file-base64'

const InfrastructureExposureCreate = () => {
  const [infoDomain, setInfoDomain] = React.useState('')
  const [infoDomainMessage, setInfoDomainMessage] = React.useState('')
  const [dateDetection, setDateDetection] = React.useState('')
  const [dateDetectionMessage, setDateDetectionMessage] = React.useState('')
  const [dateRemediation, setDateRemediation] = React.useState('')
  const [dateRemediationMessage, setDateRemediationMessage] = React.useState('')
  const [status, setStatus] = React.useState('')
  const [statusMessage, setStatusMessage] = React.useState('')
  const [type, setType] = React.useState('')
  const [typeMessage, setTypeMessage] = React.useState('')
  const [impact, setImpact] = React.useState('')
  const [impactMessage, setImpactMessage] = React.useState('')
  const [recommendation, setRecommendation] = React.useState('')
  const [recommendationMessage, setRecommendationMessage] = React.useState('')
  const [messageSuccess, setMessageSuccess] = React.useState('')
  const [images, setImages] = React.useState([])
  const [risk, setRisk] = React.useState(0)
  const [detectedBy, setDetectedBy] = React.useState('')
  const [evidenceURL, setEvidenceURL] = React.useState('')
  const [evidenceDescription, setEvidenceDescription] = React.useState('')

  function clearMessage() {
    setInfoDomainMessage('')
    setDateDetectionMessage('')
    setDateRemediationMessage('')
    setStatusMessage('')
    setImpactMessage('')
    setTypeMessage('')
    setRecommendationMessage('')
    setMessageSuccess('')
  }

  async function handleInfrastructureExposureCreate() {
    clearMessage()
    let data = {
      info_domain_or_ip: infoDomain,
      date_detection: dateDetection,
      status: status,
      type: type,
      date_remediation: dateRemediation,
      recommendation: recommendation,
      impact: impact,
      screenshots: images,
      risk: risk,
      detected_by: detectedBy
    }
    if (evidenceURL && evidenceDescription) {
      data['evidences'] = [{
        'url': evidenceURL,
        'description': evidenceDescription
      }]
    }
    const response = await API.exposure.create(data)
    if (response.ok) {
      setMessageSuccess(response.message)
    } else if (response.message === 'No es formato JSON') {
      setInfoDomainMessage('El campo es obligatorio')
      setDateDetectionMessage('El campo es obligatorio')
      setStatusMessage('El campo es obligatorio')
      setTypeMessage('El campo es obligatorio')
      setImpactMessage('El campo es obligatorio')
    } else {
      if (response.errors.info_domain) {
        setInfoDomainMessage(response.errors.info_domain[0])
      }
      if (response.errors.date_detection) {
        setDateDetectionMessage(response.errors.date_detection[0])
      }
      if (response.errors.status) {
        setStatusMessage(response.errors.status[0])
      }
      if (response.errors.type) {
        setTypeMessage(response.errors.type[0])
      }
      if (response.errors.impact) {
        setImpactMessage(response.errors.impact[0])
      }
      if (response.errors.recommendation) {
        setRecommendationMessage(response.errors.recommendation[0])
      }
    }
  }

  function getImages(file) {
    if (file.type.split('/')[0] === 'image') {
      setImages([...images, {'name': file.name, 'base64': file.base64}])
    }
  }

  return (
    <div className='layout'>
        <Nav item='infrastructureExposure' />
        <div className='main-content'>
          <div className='header bg-gradient-info pb-8 pt-5 pt-md-6'>
            <Container fluid>
              <Button className='btn-icon btn-3' color='default' type='button'>
                <span className='btn-inner--icon'>
                  <i className='ni ni-bold-left' />
                </span>
                <Link className='btn-inner--text text-white' to='/infrastructure-exposure'>Volver</Link>
              </Button>
            </Container>
          </div>
          <Container className='mt--7' fluid>
            <div className='content-auto'>
              <div className='card bg-secondary shadow'>
                <div className='card-header bg-white border-0'>
                  <div className='row align-items-center'>
                    <div className='col-8'>
                      <h3 className='mb-0'>Nueva exposición de infraestructura</h3>
                      {messageSuccess && (
                        <span className='badge badge-pill badge-success'>{messageSuccess}</span>
                      )}
                    </div>
                    <div className='col-4 text-right'>
                      <span className='btn btn-sm btn-primary text-white' onClick={handleInfrastructureExposureCreate}>Crear una exposición de infraestructura</span>
                    </div>
                  </div>
                </div>
                <div className='card-body'>
                  <form>
                    <h6 className='heading-small text-muted mb-4'>Información de la exposición de infraestructura</h6>
                      <div className='pl-lg-4'>
                        <div className='row'>
                          <div className='col-lg-12'>
                            <div className='form-group'>
                              <label className='form-control-label'>Descripción de dominio o dirección IP</label>
                              <textarea
                                className='form-control form-control-alternative'
                                rows='5'
                                name='infoDomain'
                                value={infoDomain}
                                onChange={(event) => {
                                  setInfoDomain(event.target.value)
                                  setInfoDomainMessage('')
                                }}
                              />
                              {infoDomainMessage && (
                                <span className='badge badge-pill badge-danger'>{infoDomainMessage}</span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-lg-6'>
                            <div className='form-group'>
                              <label className='form-control-label'>Fecha de detección</label>
                              <input
                                type='date'
                                name='dateDetection'
                                className='form-control form-control-alternative'
                                value={dateDetection}
                                onChange={(event) => {
                                  setDateDetection(event.target.value)
                                  setDateDetectionMessage('')
                                }}
                              />
                              {dateDetectionMessage && (
                                <span className='badge badge-pill badge-danger'>{dateDetectionMessage}</span>
                              )}
                            </div>
                          </div>
                          <div className='col-lg-6'>
                            <div className='form-group'>
                              <label className='form-control-label'>Fecha de mitigación</label>
                              <input
                                type='date'
                                name='dateRemediation'
                                className='form-control form-control-alternative'
                                value={dateRemediation}
                                onChange={(event) => {
                                  setDateRemediation(event.target.value)
                                  setDateRemediationMessage('')
                                }}
                              />
                              {dateRemediationMessage && (
                                <span className='badge badge-pill badge-danger'>{dateRemediationMessage}</span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-lg-6'>
                            <div className='form-group'>
                              <label className='form-control-label'>Estado</label>
                              <select
                                className='form-control form-control-alternative'
                                name='status'
                                value={status}
                                onChange={(event) => {
                                  setStatus(event.target.value)
                                  setStatusMessage('')
                                }}
                              >
                                <option value=''>Seleccione un estado</option>
                                <option value='nuevo'>Nuevo</option>
                                <option value='triage'>Triage</option>
                                <option value='investigación'>Investigación</option>
                                <option value='contención'>Contención</option>
                                <option value='cerrado'>Cerrado</option>
                              </select>
                              {statusMessage && (
                                <span className='badge badge-pill badge-danger'>{statusMessage}</span>
                              )}
                            </div>
                          </div>
                          <div className='col-lg-6'>
                            <div className='form-group'>
                              <label className='form-control-label'>Tipo</label>
                              <select
                                className='form-control form-control-alternative'
                                name='type'
                                value={type}
                                onChange={(event) => {
                                  setType(event.target.value)
                                  setTypeMessage('')
                                }}
                              >
                                <option value=''>Seleccione un tipo</option>
                                <option value='Dispositivo IoT'>Dispositivo IoT</option>
                                <option value='Aplicación Móvil'>Aplicación Móvil</option>
                                <option value='Repositorio'>Repositorio</option>
                                <option value='Base de Datos'>Base de Datos</option>
                                <option value='Infraestructura'>Infraestructura</option>
                              </select>
                              {typeMessage && (
                                <span className='badge badge-pill badge-danger'>{typeMessage}</span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-lg-6'>
                            <div className='form-group'>
                              <label className='form-control-label'>Impacto</label>
                              <textarea
                                className='form-control form-control-alternative'
                                rows='5'
                                name='impact'
                                value={impact}
                                onChange={(event) => {
                                  setImpact(event.target.value)
                                  setImpactMessage('')
                                }}
                              />
                              {impactMessage && (
                                <span className='badge badge-pill badge-danger'>{impactMessage}</span>
                              )}
                            </div>
                          </div>
                          <div className='col-lg-6'>
                            <div className='form-group'>
                              <label className='form-control-label'>Riesgo</label>
                              <input
                                type='int'
                                name='risk'
                                className='form-control form-control-alternative'
                                value={risk}
                                onChange={(event) => {
                                  const value = event.target.value
                                  if (value >= 0 && value <= 100) {
                                    setRisk(value)
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-lg-12'>
                            <div className='form-group'>
                              <label className='form-control-label'>Recomendación</label>
                              <textarea
                                className='form-control form-control-alternative'
                                rows='5'
                                name='recommendation'
                                value={recommendation}
                                onChange={(event) => {
                                  setRecommendation(event.target.value)
                                  setRecommendationMessage('')
                                }}
                              />
                              {recommendationMessage && (
                                <span className='badge badge-pill badge-danger'>{recommendationMessage}</span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-lg-6'>
                            <div className='form-group'>
                              <label className='form-control-label'>Detectado por</label>
                              <input
                                type='text'
                                name='detectedBy'
                                className='form-control form-control-alternative'
                                value={detectedBy}
                                onChange={(event) => {
                                  setDetectedBy(event.target.value)
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-lg-6'>
                            <div className='form-group'>
                              <label className='form-control-label'>Screenshots</label>
                              <div
                                className='form-control form-control-alternative'
                              >
                                <FileBase64 onDone={getImages} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <h3>Evidencia</h3>
                        <div className='row'>
                          <div className='col-lg-6'>
                            <div className='form-group'>
                              <label className='form-control-label'>URL</label>
                              <input
                                type='text'
                                name='evidenceURL'
                                className='form-control form-control-alternative'
                                placeholder='https://www.example.com'
                                value={evidenceURL}
                                onChange={(event) => {
                                  setEvidenceURL(event.target.value)
                                }}
                              />
                            </div>
                          </div>
                          <div className='col-lg-6'>
                            <div className='form-group'>
                              <label className='form-control-label'>Descripción</label>
                              <textarea
                                className='form-control form-control-alternative'
                                rows='5'
                                name='evidenceDescription'
                                value={evidenceDescription}
                                onChange={(event) => {
                                  setEvidenceDescription(event.target.value)
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          {images.map((image, index) => (
                            <div className='col-lg-3'>
                              <img src={image.base64} key={index} className='img-fluid' alt={index} />
                            </div>
                          ))}
                        </div>
                      </div>
                  </form>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
  )

}

export default InfrastructureExposureCreate
