import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import Nav from './Nav'
import Header from './Header'
import Footer from './Footer'
import { Doughnut } from 'react-chartjs-2'
import API from '../api/index'


class Home extends Component {
  constructor (props) {
    super(props)
    this.state = {
      phishingOpen: 0,
      phishingData: [0, 0, 0, 0, 0, 0, 0],
      phishingRisk: 0,
      leakOpen: 0,
      leakData: [0, 0, 0, 0, 0, 0, 0],
      leakRisk: 0,
      exposureOpen: 0,
      exposureData: [0, 0, 0, 0, 0, 0, 0],
      exposureRisk: 0,
      changeDNSOpen: 0,
      changeDNSData: [0, 0, 0, 0, 0, 0, 0],
      changeDNSRisk: 0,
      certificateSSLOpen: 0,
      certificateSSLData: [0, 0, 0, 0, 0, 0, 0],
      certificateSSLRisk: 0,
      totalRisk: 0
    }
  }

  async getRisk() {
    const response = await API.risk.detail()
    if (response.ok) {
      this.setState({
        phishingRisk: response.impersonation_risk,
        leakRisk: response.information_leak_risk,
        exposureRisk: response.infrastructure_exposure_risk,
        changeDNSRisk: response.dns_risk,
        certificateSSLRisk: response.ssl_risk,
        totalRisk: response.total_risk
      })
    }
  }

  async getPhishing() {
    const response = await API.phishing.status()
    this.setState({
      phishingOpen: response.open,
      phishingData: response.week
    })
  }

  async getLeak() {
    const response = await API.leak.status()
    this.setState({
      leakOpen: response.open,
      leakData: response.week
    })
  }

  async getExposure () {
    const response = await API.exposure.status()
    this.setState({
      exposureOpen: response.open,
      exposureData: response.week
    })
  }

  async getChangeDNS () {
    const response = await API.dns.status()
    this.setState({
      changeDNSOpen: response.open,
      changeDNSData: response.week
    })
  }

  async getCertificateSSL () {
    const response = await API.ssl.status()
    this.setState({
      certificateSSLOpen: response.open,
      certificateSSLData: response.week
    })

  }

  componentDidMount () {
    this.getPhishing()
    this.getLeak()
    this.getExposure()
    this.getChangeDNS()
    this.getCertificateSSL()
    this.getRisk()
  }

  render () {
    return (
      <div className='layout'>
        <Nav item='home' />
        <div className='main-content' ref='mainContent'>
          <Header />
          <div className='header bg-gradient-info pb-8 pt-5'>
            <Container fluid>
              <div className='header-body'>
                <Row>
                  <Col lg='6' xl='4'>
                    <Link to={'/impersonation'}>
                      <Card className='card-stats mb-4 mb-xl-0'>
                        <CardBody>
                          <Row>
                            <div className='col'>
                              <CardTitle
                                tag='h6'
                                className='text-uppercase text-muted mb-0'
                              >
                                Suplantaciones
                              </CardTitle>
                              <span className='h2 font-weight-bold mb-0'>
                                {this.state.phishingOpen}
                              </span>
                            </div>
                            <Col className='col-auto'>
                              <div className='icon icon-shape bg-info text-white rounded-circle shadow'>
                                <i className='ni ni-box-2' />
                              </div>
                            </Col>
                          </Row>
                          <p className='mb-0 text-muted text-sm'>
                            <span className='text-nowrap text-info'>Riesgo de <strong>{this.state.phishingRisk.toFixed(2)}%</strong></span>
                          </p>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                  <Col lg='6' xl='4'>
                    <Link to={'/information-leak'}>
                      <Card className='card-stats mb-4 mb-xl-0'>
                        <CardBody>
                          <Row>
                            <div className='col'>
                              <CardTitle
                                tag='h6'
                                className='text-uppercase text-muted mb-0'
                              >
                                Fugas de información
                              </CardTitle>
                              <span className='h2 font-weight-bold mb-0'>
                                {this.state.leakOpen}
                              </span>
                            </div>
                            <Col className='col-auto'>
                              <div className='icon icon-shape bg-success text-white rounded-circle shadow'>
                                <i className='ni ni-lock-circle-open' />
                              </div>
                            </Col>
                          </Row>
                          <p className='mb-0 text-muted text-sm'>
                            <span className='text-nowrap text-info'>Riesgo de <strong>{this.state.leakRisk.toFixed(2)}%</strong></span>
                          </p>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                  <Col lg='6' xl='4'>
                    <Link to={'/infrastructure-exposure'}>
                      <Card className='card-stats mb-4 mb-xl-0'>
                        <CardBody>
                          <Row>
                            <div className='col'>
                              <CardTitle
                                tag='h6'
                                className='text-uppercase text-muted mb-0'
                              >
                                Exposición infraestructura
                              </CardTitle>
                              <span className='h2 font-weight-bold mb-0'>{this.state.exposureOpen}</span>
                            </div>
                            <Col className='col-auto'>
                              <div className='icon icon-shape bg-default text-white rounded-circle shadow'>
                                <i className='ni ni-world-2' />
                              </div>
                            </Col>
                          </Row>
                          <p className='mb-0 text-muted text-sm'>
                            <span className='text-nowrap text-info'>Riesgo de <strong>{this.state.exposureRisk.toFixed(2)}%</strong></span>
                          </p>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col lg='6' xl='4'>
                    <Link to={'/change-dns'}>
                      <Card className='card-stats mb-4 mb-xl-0'>
                        <CardBody>
                          <Row>
                            <div className='col'>
                              <CardTitle
                                tag='h6'
                                className='text-uppercase text-muted mb-0'
                              >
                                Cambios DNS
                              </CardTitle>
                              <span className='h2 font-weight-bold mb-0'>{this.state.changeDNSOpen}</span>
                            </div>
                            <Col className='col-auto'>
                              <div className='icon icon-shape bg-danger text-white rounded-circle shadow'>
                                <i className='ni ni-bullet-list-67' />
                              </div>
                            </Col>
                          </Row>
                          <p className='mb-0 text-muted text-sm'>
                            <span className='text-nowrap text-info'>Riesgo de <strong>{this.state.changeDNSRisk.toFixed(2)}%</strong></span>
                          </p>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                  <Col lg='6' xl='4'>
                    <Link to={'/certificate-ssl'}>
                      <Card className='card-stats mb-4 mb-xl-0'>
                        <CardBody>
                          <Row>
                            <div className='col'>
                              <CardTitle
                                tag='h6'
                                className='text-uppercase text-muted mb-0'
                              >
                                Certificado SSL
                              </CardTitle>
                              <span className='h2 font-weight-bold mb-0'>{this.state.certificateSSLOpen}</span>
                            </div>
                            <Col className='col-auto'>
                              <div className='icon icon-shape bg-primary text-white rounded-circle shadow'>
                                <i className='ni ni-paper-diploma' />
                              </div>
                            </Col>
                          </Row>
                          <p className='mb-0 text-muted text-sm'>
                            <span className='text-nowrap text-info'>Riesgo de <strong>{this.state.certificateSSLRisk.toFixed(2)}%</strong></span>
                          </p>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                  <Col lg='6' xl='4'>
                    <Card className='card-stats mb-4 mb-xl-0'>
                      <CardBody>
                        <Row>
                          <div className='col'>
                            <CardTitle
                              tag='h6'
                              className='text-uppercase text-muted mb-0'
                            >
                              Riesgo total
                            </CardTitle>
                            <div className='text-center'>
                              <span style={{fontSize: '2.2rem'}} className='h1 font-weight-bold mb-0 text-info text-center'>{this.state.totalRisk.toFixed(2)}%</span>
                            </div>
                          </div>
                          <Col className='col-auto'>
                            <div className='icon icon-shape bg-warning text-white rounded-circle shadow'>
                              <i className='ni ni-money-coins' />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
          <Container className='mt--7' fluid>
            <div className='content-auto'>
              <div className="card">
                <div className="card-body">
                  <Doughnut
                    height={65}
                    data={{
                    labels: ['Suplantaciones', 'Fuga de información', 'Exposición de infraestructura', 'Cambios DNS', 'Certificado SSL'],
                    datasets: [
                      {
                        data: [this.state.phishingOpen, this.state.leakOpen, this.state.exposureOpen, this.state.changeDNSOpen,
                          this.state.certificateSSLOpen],
                        backgroundColor: [
                          '#bb2180',
                          '#2dce89',
                          '#172b4d',
                          '#f5365c',
                          '#5e72e4'
                        ],
                        hoverBackgroundColor: [
                          '#bb2180',
                          '#2dce89',
                          '#172b4d',
                          '#f5365c',
                          '#5e72e4'
                        ]
                      }
                    ]
                  }} />
                </div>
              </div>
            </div>
            <Footer />
          </Container>
        </div>
      </div>
    )
  }
}

export default Home
