import API from './api/index'

async function validateSession () {
  const verify = await API.auth.verify()
  if (!verify.ok) {
    window.sessionStorage.clear()
    window.location = '/'
  }
}

export default {
  validateSession
}
