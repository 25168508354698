import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import {
  Container,
  Button,
  Badge
} from 'reactstrap'
import Nav from '../Nav'
import Table from '../Table'
import API from '../../api/index'

class IC extends Component {
  constructor (props) {
    super(props)
    this.state = {
      name: 'Inteligencia Corporativa',
      columns: [
        {name: 'Empresa', key: 'name', type: 'text'},
        {name: 'Dominio', key: 'domain', type: 'text'}
      ],
      response: [],
      page: 1,
      redirect: undefined
    }
    this.handleCreate = this.handleCreate.bind(this)
    this.handlePrevious = this.handlePrevious.bind(this)
    this.handleAfter = this.handleAfter.bind(this)
    this.handleAction = this.handleAction.bind(this)
    this.handleGenerateReport = this.handleGenerateReport.bind(this)
  }

  handleCreate () {
    this.setState({
      redirect: '/inteligencia-corporativa/crear'
    })
  }

  async handleGenerateReport (id) {
    const response = await API.ic.generateReport(id)
    if (response.url) {
      window.open(response.url, '_blank')
    }
  }

  handleAction (id) {
    return (
      <div>
        <Badge color='success' onClick={() => this.handleGenerateReport(id)}>Generar reporte</Badge>
      </div>
    )
  }

  async handlePrevious () {
    if (this.state.page > 1) {
      const page = this.state.page - 1
      this.setState({ page })
      this.getDomains(page)
    }
  }

  async handleAfter () {
    if (this.state.response.length >= 10) {
      const page = this.state.page + 1
      this.setState({ page })
      this.getDomains(page)
    }
  }

  async getDomains (page) {
    const response = await API.ic.filter(page)
    this.setState({ response })
  }

  componentWillMount () {
    this.getDomains(this.state.page)
  }

  render () {
    if (this.state.redirect) {
      return (
        <Redirect to={this.state.redirect} />
      )
    }
    return (
      <div className='layout'>
        <Nav item='ic' />
        <div className='main-content' ref='mainContent'>
          <div className='header bg-gradient-info pb-8 pt-5 pt-md-8'>
            <Container fluid>
              <Button className='btn-icon btn-3' color='default' type='button' onClick={this.handleCreate}>
                <span className='btn-inner--icon'>
                  <i className='ni ni-bag-17' />
                </span>
                <span className='btn-inner--text'>Crear monitoreo</span>
              </Button>
            </Container>
          </div>
          <Container className='mt--7' fluid>
            <div className='content-auto'>
              <Table
                name={this.state.name}
                columns={this.state.columns}
                rows={this.state.response}
                page={this.state.page}
                previous={this.handlePrevious}
                after={this.handleAfter}
                action={this.handleAction}
              />
            </div>
          </Container>
        </div>
      </div>
    )
  }
}

export default IC
