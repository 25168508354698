import React from 'react'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import Authenticated from './Authenticated'
import Login from './components/Login'
import Verify2FA from './components/Verify2FA'
import Home from './components/Home'
import Configuration from './components/Configuration'
import Profile from './components/Profile'
import IC from './components/ic/IC'
import User from './components/user/User'
import UserCreate from './components/user/Create'
import ICCreate from './components/ic/Create'
import Phishing from './components/finding/phishing/Phishing'
import PhishingCreate from './components/finding/phishing/Create'
import PhishingDetail from './components/finding/phishing/Detail'
import InformationLeak from './components/finding/informationLeak/InformationLeak'
import InformationLeakCreate from './components/finding/informationLeak/Create'
import InformationLeakDetail from './components/finding/informationLeak/Detail'
import InfrastructureExposure from './components/finding/infrastructureExposure/infrastructureExposure'
import InfrastructureExposureCreate from './components/finding/infrastructureExposure/Create'
import InfrastructureExposureDetail from './components/finding/infrastructureExposure/Detail'
import ChangeDNS from './components/finding/changeDNS/changeDNS'
import ChangeDNSCreate from './components/finding/changeDNS/Create'
import ChangeDNSDetail from './components/finding/changeDNS/Detail'
import CertificateSSL from './components/finding/certificateSSL/CertificateSSL'
import CertificateSSLCreate from './components/finding/certificateSSL/Create'
import CertificateSSLDetail from './components/finding/certificateSSL/Detail'
import Upload from './components/upload/Upload'
import UploadCreate from './components/upload/Create'

function App () {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path='/' component={Login} />
          <Route exact path='/verify' component={Verify2FA} />
          <Authenticated exact path='/home' component={Home} />
          <Authenticated exact path='/configuration' component={Configuration} />
          <Authenticated exact path='/profile' component={Profile} />
          <Authenticated exact path='/user' component={User} />
          <Authenticated exact path='/upload' component={Upload} />
          <Authenticated exact path='/upload/create' component={UploadCreate} />
          <Authenticated exact path='/impersonation' component={Phishing} />
          <Authenticated exact path='/impersonation/create' component={PhishingCreate} />
          <Authenticated exact path='/impersonation/detail/:id' component={PhishingDetail} />
          <Authenticated exact path='/information-leak' component={InformationLeak} />
          <Authenticated exact path='/information-leak/create' component={InformationLeakCreate} />
          <Authenticated exact path='/information-leak/detail/:id' component={InformationLeakDetail} />
          <Authenticated exact path='/infrastructure-exposure' component={InfrastructureExposure} />
          <Authenticated exact path='/infrastructure-exposure/create' component={InfrastructureExposureCreate} />
          <Authenticated exact path='/infrastructure-exposure/detail/:id' component={InfrastructureExposureDetail} />
          <Authenticated exact path='/change-dns' component={ChangeDNS} />
          <Authenticated exact path='/change-dns/create' component={ChangeDNSCreate} />
          <Authenticated exact path='/change-dns/detail/:id' component={ChangeDNSDetail} />
          <Authenticated exact path='/certificate-ssl' component={CertificateSSL} />
          <Authenticated exact path='/certificate-ssl/create' component={CertificateSSLCreate} />
          <Authenticated exact path='/certificate-ssl/detail/:id' component={CertificateSSLDetail} />
          <Authenticated exact path='/user/create' component={UserCreate} />
          <Authenticated exact path='/inteligencia-corporativa' component={IC} />
          <Authenticated exact path='/inteligencia-corporativa/crear' component={ICCreate} />
        </Switch>
      </div>
    </Router>
  )
}

export default App
