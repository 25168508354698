import axios from 'axios'
import config from './config'

const URL = `${config.URL}/user`

export async function create (data) {
  const result = await axios.post(`${URL}/create`, data, config.configWithToken())
  return result.data
}

export async function filter (page) {
  const result = await axios.get(`${URL}/filter/${page}`, config.configWithToken())
  return result.data
}

export async function detail () {
  const result = await axios.get(`${URL}/detail`, config.configWithToken())
  return result.data
}

export async function update (data) {
  const result = await axios.post(`${URL}/update`, data, config.configWithToken())
  return result.data
}

export async function changePassword (data) {
  const result = await axios.post(`${URL}/change/password`, data, config.configWithToken())
  return result.data
}

export default {
  create,
  filter,
  detail,
  update,
  changePassword
}