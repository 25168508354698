import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { getItem } from '../api/utils'

const Header = () => {
  const [redirect, setRedirect] = React.useState(false)
  const firstName = getItem('firstName')
  const lastName = getItem('lastName')

  function handleLogout () {
    window.sessionStorage.clear()
    setRedirect('/')
  }

  if (redirect) {
    return (
      <Redirect to={redirect} />
    )
  }

  return (
    <nav className='navbar navbar-top navbar-expand-md navbar-dark'>
      <div className='container-fluid'>
        <span className='h4 mb-0 text-white text-uppercase d-none d-lg-inline-block'>Dashboard</span>
        <ul className='navbar-nav align-items-center d-none d-md-flex'>
          <li className='nav-item dropdown'>
            <a className='nav-link pr-0' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
              <div className='media align-items-center'>
                <div className='media-body ml-2 d-none d-lg-block'>
                  <span className='mb-0 text-sm font-weight-bold text-white'>Bienvenido, {firstName ? firstName : ''} {lastName ? lastName : ''}</span>
                  {' '}
                  <i className='ni ni-bold-down'></i>
                </div>
              </div>
            </a>
            <div className='dropdown-menu dropdown-menu-arrow dropdown-menu-right'>
              <div className='dropdown-header noti-title'>
                <h6 className='text-overflow m-0'>Bienvenido!</h6>
              </div>
              <Link className='dropdown-item' to='/profile'>
                <i className='ni ni-single-02' />
                <span>Mi perfil</span>
              </Link>
              <Link className='dropdown-item' to='/configuration'>
                <i className='ni ni-settings-gear-65'></i>
                <span>Configuración</span>
              </Link>
              {/* <a className='dropdown-item'>
                <i className='ni ni-calendar-grid-58'></i>
                <span>Actividad</span>
                </a> */}
              <div className='dropdown-divider'></div>
              <a className='dropdown-item' onClick={() => handleLogout()}>
                <i className='ni ni-user-run'></i>
                <span>Cerrar sesión</span>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    )
}

export default Header
