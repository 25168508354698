import axios from 'axios'
import config from './config'

const URL = `${config.URL}/upload`

export async function create (data) {
  const result = await axios.post(`${URL}/create`, data, config.configWithToken())
  return result.data
}

export async function filter (page) {
  const result = await axios.get(`${URL}/filter/${page}`, config.configWithToken())
  return result.data
}

export async function deleteUpload (data) {
  const result = await axios.post(`${URL}/delete`, data, config.configWithToken())
  return result.data
}

export default {
  create,
  filter,
  deleteUpload
}