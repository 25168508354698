import axios from 'axios'
import config from './config'

const URL = `${config.URL}/risk`

async function detail () {
  const result = await axios.get(`${URL}/query`, config.configWithToken())
  return result.data
}

export default {
  detail
}