import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container
} from 'reactstrap'
import Logo from '../assets/img/cattleya.png'
import LogoClient from '../assets/img/tuya.svg'
import API from '../api/index'

class Menu extends Component {
  constructor (props) {
    super(props)
    this.state = {
      item: props.item,
      redirect: undefined,
      profile: undefined
    }
    this.handleLogout = this.handleLogout.bind(this)
    this.handleRedirect = this.handleRedirect.bind(this)
  }

  async getProfile() {
    const data = await API.auth.verify()
    if (data.ok) {
      this.setState({
        profile: data.profile
      })
    }
  }

  componentWillMount () {
    this.getProfile()
  }

  updateState (key, value) {
    this.setState({
      [key]: value
    })
  }

  componentWillReceiveProps (newProps) {
    if (newProps.item !== this.state.item) {
      this.updateState('item', newProps.item)
    }
  }

  handleLogout () {
    window.sessionStorage.clear()
    this.updateState('redirect', '/')
  }

  handleRedirect (url) {
    const pathname = window.location.pathname
    if (pathname !== url) {
      this.updateState('redirect', url)
    }
  }

  render () {
    if (this.state.redirect) {
      return (
        <Redirect to={this.state.redirect} />
      )
    }
    return (
      <Navbar
        className='navbar-vertical fixed-left navbar-light bg-white'
        expand='md'
        id='sidenav-main'
      >
        <Container fluid>
          <NavbarBrand className='pt-0'>
            <img
              alt='Cattleya'
              className='navbar-brand-img'
              src={Logo}
            />
          </NavbarBrand>
          <NavbarBrand className='pt-0'>
            <img
              alt='Tuya'
              className='navbar-brand-img'
              src={LogoClient}
            />
          </NavbarBrand>
          <Collapse navbar>
            <Nav navbar>
              <NavItem key='1'>
                <NavLink
                  onClick={() => this.handleRedirect('/home')}
                  active={this.state.item === 'home'}
                >
                  <i className='ni ni-tv-2 text-blue' />
                  Dashboard
                </NavLink>
              </NavItem>
              {this.state.profile === 'administrator' && (
                <NavItem key='2'>
                  <NavLink
                    onClick={() => this.handleRedirect('/user')}
                    active={this.state.item === 'user'}
                  >
                    <i className='ni ni-single-02 text-yellow' /> Usuarios
                  </NavLink>
                </NavItem>
              )}
              {this.state.profile === 'administrator' && (
                <NavItem key='10'>
                  <NavLink
                    onClick={() => this.handleRedirect('/upload')}
                    active={this.state.item === 'upload'}
                  >
                    <i className='ni ni-cloud-upload-96 text-success' /> Subir archivos
                  </NavLink>
                </NavItem>
              )}
              {/* <NavItem key='3'>
                <NavLink
                  onClick={() => console.log('ticket')}
                  active={this.state.item === 'ticket'}
                >
                  <i className='ni ni-tag text-blue' />
                  Tickets
                </NavLink>
              </NavItem>
              <NavItem key='3'>
                <NavLink
                  onClick={() => this.handleRedirect('/inteligencia-corporativa')}
                  active={this.state.item === 'ic'}
                >
                  <i className='ni ni-check-bold text-primary' /> Inteligencia Corporativa
                </NavLink>
              </NavItem> */}
              <NavItem key='4'>
                <NavLink
                  onClick={() => this.handleRedirect('/impersonation')}
                  active={this.state.item === 'phishing'}
                >
                  <i className='ni ni-box-2 text-info' /> Suplantación
                </NavLink>
              </NavItem>
              <NavItem key='5'>
                <NavLink
                  onClick={() => this.handleRedirect('/information-leak')}
                  active={this.state.item === 'informationLeak'}
                >
                  <i className='ni ni-lock-circle-open text-success' /> Fuga de información
                </NavLink>
              </NavItem>
              <NavItem key='6'>
                <NavLink
                  onClick={() => this.handleRedirect('/infrastructure-exposure')}
                  active={this.state.item === 'infrastructureExposure'}
                >
                  <i className='ni ni-world-2 text-default' /> Exposición infraestructura
                </NavLink>
              </NavItem>
              <NavItem key='7'>
                <NavLink
                  onClick={() => this.handleRedirect('/change-dns')}
                  active={this.state.item === 'changeDNS'}
                >
                  <i className='ni ni-bullet-list-67 text-danger' /> Cambios de DNS
                </NavLink>
              </NavItem>
              <NavItem key='8'>
                <NavLink
                  onClick={() => this.handleRedirect('/certificate-ssl')}
                  active={this.state.item === 'certificateSSL'}
                >
                  <i className='ni ni-paper-diploma text-primary' /> Certificados SSL
                </NavLink>
              </NavItem>
              <NavItem key='9'>
                <NavLink
                  onClick={this.handleLogout}
                >
                  <i className='ni ni-button-power text-warning' /> Salir
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    )
  }
}

export default Menu
