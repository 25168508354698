import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import {
  Container,
  Button,
  Badge
} from 'reactstrap'
import Nav from '../../Nav'
import Table from '../../Table'
import Delete from '../Delete'
import API from '../../../api/index'

const Phishing = () => {
  const [redirect, setRedirect] = React.useState('')
  const [rows, setRows] = React.useState([])
  const [page, setPage] = React.useState(1)
  const [profile, setProfile] = React.useState('client')
  const [openDelete, setOpenDelete] = React.useState(false)
  const [deleteID, setDeleteID] = React.useState('')
  const [code, setCode] = React.useState('')

  async function getProfile() {
    const data = await API.auth.verify()
    if (data.ok) {
      setProfile(data.profile)
    }
  }

  const columns = [
    {name: 'ID', key: 'code', type: 'text'},
    {name: 'Fecha de detección', key: 'date_detection', type: 'text'},
    {name: 'Estado', key: 'status', type: 'status'},
    {name: 'Detectado por', key: 'detected_by', type: 'text'},
    {name: 'Riesgo', key: 'risk', type: 'risk'},
    {name: 'Fecha de takedown', key: 'date_take_down', type: 'text'},
  ]

  async function getPhishings(page) {
    const response = await API.phishing.filter(page)
    if (response.records) {
      setRows(response.records)
    } else {
      setRedirect('/home')
    }
  }

  React.useEffect(() => {
    getProfile()
  }, [])

  React.useEffect(() => {
    getPhishings(1)
  }, [])

  async function handlePrevious () {
    if (page > 1) {
      setPage(page - 1)
      getPhishings(page - 1)
    }
  }

  async function handleAfter () {
    if (rows.length >= 10) {
      setPage(page + 1)
      getPhishings(page + 1)
    }
  }

  function deleteRegister(id, codeRow) {
    setDeleteID(id)
    setCode(codeRow)
    setOpenDelete(true)
  }

  async function deleteFinding(id) {
    const response = await API.phishing.deleteFinding(id)
    if (response.ok) {
      setOpenDelete(false)
      await getPhishings(1)
    }
  }

  function handleAction(id, code) {
    return (
      <div>
        {profile === 'administrator' && (
          <Badge color='warning add-pointer' onClick={() => deleteRegister(id, code)}>Borrar</Badge>
        )}
        {' '}
        <Badge color='info add-pointer' onClick={() => setRedirect(`/impersonation/detail/${id}`)}>Ver detalle</Badge>
      </div>
    )
  }

  function handleClicRow(id) {
    setRedirect(`/impersonation/detail/${id}`)
  }

  if (redirect) {
    return (
      <Redirect to={redirect} />
    )
  }

  return (
    <div className='layout'>
      <Nav item='phishing' />
      <div className='main-content'>
        <div className='header bg-gradient-info pb-8 pt-5 pt-md-6'>
          {profile === 'administrator' && (
            <Container fluid>
              <Button className='btn-icon btn-3' color='default' type='button'>
                <span className='btn-inner--icon'>
                  <i className='ni ni-bag-17' />
                </span>
                <Link className='btn-inner--text text-white' to='/impersonation/create'>Reportar suplantación</Link>
              </Button>
            </Container>
          )}
        </div>
        <Container className='mt--7' fluid>
          <div className='content-auto'>
            <Delete
              open={openDelete}
              setOpen={setOpenDelete}
              deleteID={deleteID}
              code={code}
              deleteFinding={deleteFinding}
            />
            <Table
              name='Suplantaciones reportados'
              columns={columns}
              rows={rows}
              page={page}
              previous={handlePrevious}
              after={handleAfter}
              action={handleAction}
              handleClicRow={handleClicRow}
            />
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Phishing
