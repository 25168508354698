import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
  Container,
  Button,
  PopoverBody,
  Badge,
  UncontrolledPopover
} from 'reactstrap'
import Nav from '../Nav'
import Delete from './Delete'
import Table from '../Table'
import API from '../../api/index'

const Upload = () => {
  const [redirect, setRedirect] = React.useState('')
  const [rows, setRows] = React.useState([])
  const [page, setPage] = React.useState(1)
  const [profile, setProfile] = React.useState('client')
  const [openDelete, setOpenDelete] = React.useState(false)
  const [deleteID, setDeleteID] = React.useState('')
  const [code, setCode] = React.useState('')

  async function getProfile() {
    const data = await API.auth.verify()
    if (data.ok) {
      setProfile(data.profile)
    }
  }

  const columns = [
    {name: 'ID', key: 'code', type: 'text'},
    {name: 'Descripción', key: 'description', type: 'text'}
  ]

  async function getUploads(page) {
    const response = await API.upload.filter(page)
    if (response.records) {
      setRows(response.records)
    } else {
      setRedirect('/home')
    }
  }

  React.useEffect(() => {
    getProfile()
  }, [])

  React.useEffect(() => {
    getUploads(1)
  }, [])

  async function handlePrevious () {
    if (page > 1) {
      setPage(page - 1)
      getUploads(page - 1)
    }
  }

  async function handleAfter () {
    if (rows.length >= 10) {
      setRows(page + 1)
      getUploads(page + 1)
    }
  }

  async function deleteUpload(id) {
    const response = await API.upload.deleteUpload({
      _id: id
    })
    if (response.ok) {
      setOpenDelete(false)
      await getUploads(1)
    }
  }

  function handleAction(id, code, url) {
    return (
      <div>
        {profile === 'administrator' && (
          <Badge color='warning add-pointer' onClick={() => deleteRegister(id, code)}>Borrar</Badge>
        )}
        <CopyToClipboard text={url}>
          <div>
            <Button color='success' id={`url-${id}`} type='button' size='sm'>Copiar URL</Button>
            <UncontrolledPopover placement="top" target={`url-${id}`}>
              <PopoverBody>
                La URL fue copiada exitosamente
              </PopoverBody>
            </UncontrolledPopover>
          </div>
        </CopyToClipboard>
      </div>
    )
  }

  function deleteRegister(id, codeRow) {
    setDeleteID(id)
    setCode(codeRow)
    setOpenDelete(true)
  }

  if (redirect) {
    return (
      <Redirect to={redirect} />
    )
  }

  return (
    <div className='layout'>
      <Nav item='upload' />
      <div className='main-content'>
        <div className='header bg-gradient-info pb-8 pt-5 pt-md-6'>
          {profile === 'administrator' && (
            <Container fluid>
              <Button className='btn-icon btn-3' color='default' type='button'>
                <span className='btn-inner--icon'>
                  <i className='ni ni-bag-17' />
                </span>
                <Link className='btn-inner--text text-white' to='/upload/create'>Subir documento</Link>
              </Button>
            </Container>
          )}
        </div>
        <Container className='mt--7' fluid>
          <div className='content-auto'>
            <Delete
              open={openDelete}
              setOpen={setOpenDelete}
              deleteID={deleteID}
              code={code}
              deleteUpload={deleteUpload}
            />
            <Table
              name='Documentos subidos'
              columns={columns}
              rows={rows}
              page={page}
              previous={handlePrevious}
              after={handleAfter}
              action={handleAction}
              handleClicRow={() => console.log('')}
              upload={true}
            />
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Upload
