import axios from 'axios'
import config from './config'

const URL = `${config.URL}/notification`

export async function all () {
  const result = await axios.get(`${URL}/notifications`, config.configWithToken())
  return result.data
}

export async function update (data) {
  const result = await axios.post(`${URL}/update`, data, config.configWithToken())
  return result.data
}

export default {
  all,
  update
}