import React from 'react'
import { Link } from 'react-router-dom'
import {
  Container,
  Button
} from 'reactstrap'
import Nav from '../Nav'
import API from '../../api/index'

const UserCreate = () => {
  const [firstName, setFirstName] = React.useState('')
  const [firstNameMessage, setFirstNameMessage] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [lastNameMessage, setLastNameMessage] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [emailMessage, setEmailMessage] = React.useState('')
  const [profile, setProfile] = React.useState('')
  const [profileMessage, setProfileMessage] = React.useState('')
  const [phone, setPhone] = React.useState('')
  const [phoneMessage, setPhoneMessage] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [passwordMessage, setPasswordMessage] = React.useState('')
  const [passwordRepeat, setPasswordRepeat] = React.useState('')
  const [passwordRepeatMessage, setPasswordRepeatMessage] = React.useState('')
  const [messageSuccess, setMessageSuccess] = React.useState('')
  
  function clearMessage() {
    setFirstNameMessage('')
    setLastNameMessage('')
    setEmailMessage('')
    setProfileMessage('')
    setPasswordMessage('')
    setPasswordRepeatMessage('')
    setMessageSuccess('')
    setPhoneMessage('')
  }

  async function handleCreateUser() {
    clearMessage()
    if (password === passwordRepeat) {
      const response = await API.user.create({
        first_name: firstName,
        last_name: lastName,
        profile: profile,
        email: email,
        phone: phone,
        password: password
      })
      if (response.ok) {
        clearMessage()
        setMessageSuccess(response.message)
      } else if (response.message === 'email already exists') {
        setEmailMessage('El correo electrónico ya existe')
      } else if (response.message === 'No es formato JSON') {
        setFirstNameMessage('El campo es obligatorio')
        setLastNameMessage('El campo es obligatorio')
        setEmailMessage('El campo es obligatorio')
        setProfileMessage('El campo es obligatorio')
        setPasswordMessage('El campo es obligatorio')
        setPasswordRepeatMessage('El campo es obligatorio')
        setPhoneMessage('El campo es obligatorio')
      } else {
        if (response.errors.first_name) {
          setFirstNameMessage(response.errors.first_name[0])
        }
        if (response.errors.last_name) {
          setLastNameMessage(response.errors.last_name[0])
        }
        if (response.errors.email) {
          setEmailMessage(response.errors.email[0])
        }
        if (response.errors.profile) {
          setProfileMessage(response.errors.profile[0])
        }
        if (response.errors.phone) {
          setPhoneMessage(response.errors.phone[0])
        }
        if (response.errors.password) {
          setPasswordMessage(response.errors.password[0])
          setPasswordRepeatMessage(response.errors.password[0])
        }
      }
    } else {
      setPasswordMessage('Las contraseñas no coinciden, por favor intente de nuevo.')
    }
  }

  return (
    <div className='layout'>
        <Nav item='user' />
        <div className='main-content'>
          <div className='header bg-gradient-info pb-8 pt-5 pt-md-6'>
            <Container fluid>
              <Button className='btn-icon btn-3' color='default' type='button' onClick={() => console.log('')}>
                <span className='btn-inner--icon'>
                  <i className='ni ni-bold-left' />
                </span>
                <Link className='btn-inner--text text-white' to='/user'>Volver</Link>
              </Button>
            </Container>
          </div>
          <Container className='mt--7' fluid>
            <div className='content-auto'>
              <div className='card bg-secondary shadow'>
                <div className='card-header bg-white border-0'>
                  <div className='row align-items-center'>
                    <div className='col-8'>
                      <h3 className='mb-0'>Nuevo usuario</h3>
                      {messageSuccess && (
                        <span className='badge badge-pill badge-success'>{messageSuccess}</span>
                      )}
                    </div>
                    <div className='col-4 text-right'>
                      <span className='btn btn-sm btn-primary text-white' onClick={handleCreateUser}>Crear usuario</span>
                    </div>
                  </div>
                </div>
                <div className='card-body'>
                  <form>
                    <h6 className='heading-small text-muted mb-4'>Información del usuario</h6>
                      <div className='pl-lg-4'>
                        <div className='row'>
                          <div className='col-lg-6'>
                            <div className='form-group'>
                              <label className='form-control-label'>Nombre</label>
                              <input
                                type='text'
                                name='first_name'
                                className='form-control form-control-alternative'
                                placeholder='Nombre'
                                value={firstName}
                                onChange={(event) => {
                                  setFirstName(event.target.value)
                                  setFirstNameMessage('')
                                }}
                              />
                              {firstNameMessage && (
                                <span className='badge badge-pill badge-danger'>{firstNameMessage}</span>
                              )}
                            </div>
                          </div>
                          <div className='col-lg-6'>
                            <div className='form-group'>
                              <label className='form-control-label'>Apellido</label>
                              <input
                                type='text'
                                name='last_name'
                                className='form-control form-control-alternative'
                                placeholder='Apellido'
                                value={lastName}
                                onChange={(event) => {
                                  setLastName(event.target.value)
                                  setLastNameMessage('')
                                }}
                              />
                              {lastNameMessage && (
                                <span className='badge badge-pill badge-danger'>{lastNameMessage}</span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-lg-6'>
                            <div className='form-group'>
                              <label className='form-control-label' >Correo electrónico</label>
                              <input
                                type='email'
                                name='email'
                                className='form-control form-control-alternative'
                                placeholder='Correo electrónico'
                                value={email}
                                onChange={(event) => {
                                  setEmail(event.target.value)
                                  setEmailMessage('')
                                }}
                              />
                              {emailMessage && (
                                <span className='badge badge-pill badge-danger'>{emailMessage}</span>
                              )}
                            </div>
                          </div>
                          <div className='col-lg-6'>
                            <div className='form-group'>
                              <label className='form-control-label'>Perfil</label>
                              <select
                                className='form-control form-control-alternative'
                                name='profile'
                                value={profile}
                                onChange={(event) => {
                                  setProfile(event.target.value)
                                  setProfileMessage('')
                                }}
                              >
                                <option value=''>Seleccione un perfil</option>
                                <option value='administrator'>Administrador</option>
                                <option value='client'>Cliente</option>
                              </select>
                              {profileMessage && (
                                <span className='badge badge-pill badge-danger'>{profileMessage}</span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-lg-6'>
                            <div className='form-group'>
                              <label className='form-control-label'>Celular</label>{' '}
                              <input
                                type='text'
                                name='phone'
                                className='form-control form-control-alternative'
                                placeholder='Celular'
                                value={phone}
                                onChange={(event) => {
                                  setPhone(event.target.value)
                                  setPhoneMessage('')
                                }}
                              />
                              {phoneMessage && (
                                <span className='badge badge-pill badge-danger'>{phoneMessage}</span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-lg-6'>
                            <div className='form-group'>
                              <label className='form-control-label'>Contraseña</label>{' '}
                              <input
                                type='password'
                                name='password'
                                className='form-control form-control-alternative'
                                placeholder='Contraseña'
                                value={password}
                                onChange={(event) => {
                                  setPassword(event.target.value)
                                  setPasswordMessage('')
                                }}
                              />
                              {passwordMessage && (
                                <span className='badge badge-pill badge-danger'>{passwordMessage}</span>
                              )}
                            </div>
                          </div>
                          <div className='col-lg-6'>
                            <div className='form-group'>
                              <label className='form-control-label'>Contraseña (Repetir)</label>
                              <input
                                type='password'
                                name='passwordRepeat'
                                className='form-control form-control-alternative'
                                placeholder='Contraseña (Repetir)'
                                value={passwordRepeat}
                                onChange={(event) => {
                                  setPasswordRepeat(event.target.value)
                                  setPasswordRepeatMessage('')
                                }}
                              />
                              {passwordRepeatMessage && (
                                <span className='badge badge-pill badge-danger'>{passwordRepeatMessage}</span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                  </form>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
  )

}

export default UserCreate
