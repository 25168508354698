import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import {
  Container,
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Card,
  CardHeader
} from 'reactstrap'
import Nav from '../Nav'
import API from '../../api/index'

class Create extends Component {
  constructor (props) {
    super(props)
    this.state = {
      name: undefined,
      domain: undefined,
      objective: undefined,
      redirect: undefined
    }
    this.handleChange = this.handleChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  async onSubmit () {
    const response = await API.ic.create(this.state.name, this.state.domain, this.state.objective)
    if (response.ok) {
      this.setState({
        'redirect': '/inteligencia-corporativa'
      })
    }
  }

  async handleChange (event) {
    const value = event.target.value
    const name = event.target.name
    this.setState({
      [name]: value
    })
  }

  render () {
    if (this.state.redirect) {
      return (
        <Redirect to={this.state.redirect} />
      )
    }
    return (
      <div className='layout'>
        <Nav item='ic' />
        <div className='main-content' ref='mainContent'>
          <div className='header bg-gradient-info pb-8 pt-5 pt-md-8'>
            <Container fluid>
              <Button className='btn-icon btn-3' color='default' type='button' onClick={this.onSubmit}>
                <span className='btn-inner--icon'>
                  <i className='ni ni-bag-17' />
                </span>
                <span className='btn-inner--text'>Guardar</span>
              </Button>
            </Container>
          </div>
          <Container className='mt--7' fluid>
            <div className='content-auto'>
              <Card className='shadow'>
                <CardHeader className='border-0'>
                  <h3 className='mb-0'>Crear monitoreo</h3>
                </CardHeader>
                <Container fluid>
                  <Form>
                    <Row>
                      <Col md='6'>
                        <FormGroup>
                          <Input
                            id='name'
                            name='name'
                            placeholder='Nombre de la empresa'
                            type='text'
                            value={this.state.name}
                            onChange={this.handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md='6'>
                        <FormGroup>
                          <Input
                            id='domain'
                            name='domain'
                            placeholder='URL'
                            type='url'
                            value={this.state.domain}
                            onChange={this.handleChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md='12'>
                        <FormGroup>
                          <Input
                            id='objective'
                            name='objective'
                            placeholder='Objetivo'
                            rows='3'
                            type='textarea'
                            value={this.state.objective}
                            onChange={this.handleChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </Container>
              </Card>
            </div>
          </Container>
        </div>
      </div>
    )
  }
}

export default Create
