import React, { Component } from 'react'
import { Redirect, Link } from 'react-router-dom'
import {
  Container,
  Button
} from 'reactstrap'
import Nav from '../Nav'
import Table from '../Table'
import API from '../../api/index'

class User extends Component {
  constructor (props) {
    super(props)
    this.state = {
      name: 'Usuarios del sistema',
      columns: [
        {name: 'Nombre', key: 'first_name', type: 'text'},
        {name: 'Apellido', key: 'last_name', type: 'text'},
        {name: 'E-mail', key: 'email', type: 'text'},
        {name: 'Perfil', key: 'profile', type: 'text'},
        {name: 'Activo', key: 'is_active', type: 'status'}
      ],
      response: [],
      page: 1,
      redirect: undefined
    }
    this.handleCreate = this.handleCreate.bind(this)
    this.handlePrevious = this.handlePrevious.bind(this)
    this.handleAfter = this.handleAfter.bind(this)
  }

  async getUsers(page) {
    const response = await API.user.filter(page)
    if (response.users) {
      this.setState({
        'response': response.users
      })
    } else {
      this.setState({
        'redirect': '/home'
      })
    }
  }

  async handlePrevious () {
    if (this.state.page > 1) {
      const page = this.state.page - 1
      this.setState({ page })
      this.getUsers(page)
    }
  }

  async handleAfter () {
    if (this.state.response.length >= 10) {
      const page = this.state.page + 1
      this.setState({ page })
      this.getUsers(page)
    }
  }


  componentWillMount () {
    this.getUsers(this.state.page)
  }

  async handleCreate () {
    this.setState({
      'redirect': '/user/create'
    })
  }

  render () {
    if (this.state.redirect) {
      return (
        <Redirect to={this.state.redirect} />
      )
    }
    return (
      <div className='layout'>
        <Nav item='user' />
        <div className='main-content' ref='mainContent'>
          <div className='header bg-gradient-info pb-8 pt-5 pt-md-6'>
            <Container fluid>
              <Button className='btn-icon btn-3' color='default' type='button' onClick={this.handleCreate}>
                <span className='btn-inner--icon'>
                  <i className='ni ni-bag-17' />
                </span>
                <Link className='btn-inner--text text-white' to='/user/create'>Crear usuario</Link>
              </Button>
            </Container>
          </div>
          <Container className='mt--7' fluid>
            <div className='content-auto'>
              <Table
                name={this.state.name}
                columns={this.state.columns}
                rows={this.state.response}
                page={this.state.page}
                previous={this.handlePrevious}
                after={this.handleAfter}
                handleClicRow={() => console.log('.')}
              />
            </div>
          </Container>
        </div>
      </div>
    )
  }
}

export default User