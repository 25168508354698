import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Modal,
  Button
} from 'reactstrap'
import Nav from './Nav'
import Header from './Header'
import API from '../api/index'
import { setItem } from '../api/utils'

const Profile = (props) => {
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [phone, setPhone] = React.useState('')
  const [messageError, setMessageError] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [passwordNew, setPasswordNew] = React.useState('')
  const [passwordNewRepeat, setPasswordNewRepeat] = React.useState('')
  const [messageErrorPassword, setMessageErrorPassword] = React.useState('')
  const [messageSuccessPassword, setMessageSucessPassword] = React.useState('')

  const [redirect, setRedirect] = React.useState(false)
  const [openModal, setOpenModal] = React.useState(false)

  async function changePassword () {
    if (passwordNew === passwordNewRepeat) {
      const is_change = await API.user.changePassword({
        password: password,
        new_password: passwordNew
      })
      if (is_change.ok) {
        setMessageSucessPassword('La contraseña fue actualizada correctamente')
      } else {
        setMessageErrorPassword('Las contraseñas no fue actualizada correctamente')
      }
    } else {
      setMessageErrorPassword('Las contraseñas no coinciden')
    }
    setPassword('')
    setPasswordNew('')
    setPasswordNewRepeat('')
  }

  async function updateProfile () {
    if (firstName && lastName && email && phone) {
      const response = await API.user.update({
        'first_name': firstName,
        'last_name': lastName,
        'email': email,
        'phone': phone
      })
      if (response.ok) {
        setItem('firstName', firstName)
        setItem('lastName', lastName)
        setOpenModal(true)
      } else {
        setMessageError('Ocurrio un error al actualizar el perfil')
      }
    }

  }

  async function getProfile () {
    const response = await API.user.detail()
    setFirstName(response.first_name)
    setLastName(response.last_name)
    setEmail(response.email)
    setPhone(response.phone)
  }

  React.useEffect(() => {
    getProfile()
  }, [])

  if (redirect) {
    return (
      <Redirect to={redirect} />
    )
  }


  return (
    <div className='layout'>
      <Nav item='home' />
      <div className='main-content'>
        <Header />
        <div className='header bg-gradient-info pb-8 pt-5 pt-md-8'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-xl-8 order-xl-1'>
                <div className='card bg-secondary shadow'>
                  <div className='card-header bg-white border-0'>
                    <div className='row align-items-center'>
                      <div className='col-8'>
                        <h3 className='mb-0'>Perfil</h3>
                        {messageError && (
                          <span className='badge badge-pill badge-warning'>{messageError}</span>
                        )}
                      </div>
                      <div className='col-4 text-right'>
                        <button onClick={() => updateProfile()} className='btn btn-sm btn-primary'>Actualizar perfil</button>
                      </div>
                    </div>
                  </div>
                  <div className='card-body'>
                    <h6 className='heading-small text-muted mb-4'>Información básica</h6>
                    <div className='pl-lg-4'>
                      <div className='row'>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label'>Nombre</label>
                            <input
                              type='text'
                              className='form-control form-control-alternative'
                              value={firstName}
                              onChange={(event) => setFirstName(event.target.value)}
                              />
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label'>Apellido</label>
                            <input
                              type='text'
                              className='form-control form-control-alternative'
                              value={lastName}
                              onChange={(event) => setLastName(event.target.value)}
                              />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label'>Correo electrónico</label>
                            <input
                              type='email'
                              className='form-control form-control-alternative'
                              value={email}
                              onChange={(event) => setEmail(event.target.value)}
                              />
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label'>Celular</label>
                            <input
                              type='text'
                              className='form-control form-control-alternative'
                              value={phone}
                              onChange={(event) => setPhone(event.target.value)}
                              />
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className='my-4' />
                  </div>
                </div>
              </div>
              <div className='col-xl-4 order-xl-1'>
                <div className='card bg-secondary shadow'>
                  <div className='card-header bg-white border-0'>
                    <div className='row align-items-center'>
                      <div className='col-8'>
                        <h3 className='mb-0'>Actualización de contraseña</h3>
                        {messageSuccessPassword && (
                          <span className='badge badge-pill badge-success'>{messageSuccessPassword}</span>
                        )}
                        {messageErrorPassword && (
                          <span className='badge badge-pill badge-warning'>{messageErrorPassword}</span>
                        )}
                      </div>
                      <div className='col-4 text-right'>
                        <button onClick={() => changePassword()} className='btn btn-sm btn-primary'>Actualizar</button>
                      </div>
                    </div>
                  </div>
                  <div className='card-body'>
                    <h6 className='heading-small text-muted mb-4'>Actualización de contraseña</h6>
                    <div className='pl-lg-4'>
                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className='form-group'>
                            <label className='form-control-label'>Contraseña actual</label>
                            <input
                              type='password'
                              className='form-control form-control-alternative'
                              value={password}
                              onChange={(event) => {
                                setPassword(event.target.value)
                                setMessageErrorPassword('')
                                setMessageSucessPassword('')
                              }}
                              />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className='form-group'>
                            <label className='form-control-label'>Nueva contraseña</label>
                            <input
                              type='password'
                              className='form-control form-control-alternative'
                              value={passwordNew}
                              onChange={(event) => {
                                setPasswordNew(event.target.value)
                                setMessageErrorPassword('')
                                setMessageSucessPassword('')
                              }}
                              />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className='form-group'>
                            <label className='form-control-label'>Nueva contraseña (Repetir)</label>
                            <input
                              type='password'
                              className='form-control form-control-alternative'
                              value={passwordNewRepeat}
                              onChange={(event) => {
                                setPasswordNewRepeat(event.target.value)
                                setMessageErrorPassword('')
                                setMessageSucessPassword('')
                              }}
                              />
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className='my-4' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className='modal-dialog-centered'
        isOpen={openModal}
        toggle={() => setRedirect('/home')}
      >
        <div className='modal-header'>
          <h4 className='modal-title text-center' id='save-configuration'>Perfil de usuario</h4>
          <button
            aria-label='Close'
            className='close'
            data-dismiss='modal'
            type='button'
            onClick={() => setRedirect('/home')}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className='modal-body'>
          <p>El perfil fue actualizado exitosamente</p>
        </div>
        <div className='modal-footer'>
          <Button
            className='ml-auto'
            color='link'
            data-dismiss='modal'
            type='button'
            onClick={() => setRedirect('/home')}
          >
            Cerrar
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default Profile
