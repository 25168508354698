import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Modal,
  Button
} from 'reactstrap'
import Nav from './Nav'
import Header from './Header'
import API from '../api/index'

const Configuration = (props) => {
  const [phishing, setPhishing] = React.useState(false)
  const [phishingID, setPhishingID] = React.useState('')
  const [informationLeak, setInformationLeak] = React.useState(false)
  const [informationLeakID, setInformationLeakID] = React.useState(false)
  const [infrastructureExposure, setInfrastructureExposure] = React.useState(false)
  const [infrastructureExposureID, setInfrastructureExposureID] = React.useState(false)
  const [changeDns, setChangeDns] = React.useState(false)
  const [changeDnsID, setChangeDnsID] = React.useState(false)
  const [certificateSSL, setCertificateSSL] = React.useState(false)
  const [certificateSSLID, setCertificateSSLID] = React.useState(false)
  const [messageNotification, setMessageNotification] = React.useState('')
  const [messageElement, setMessageElement] = React.useState('')

  const [elementOne, setElementOne] = React.useState('')
  const [elementTypeOne, setElementTypeOne] = React.useState('')
  const [elementTwo, setElementTwo] = React.useState('')
  const [elementTypeTwo, setElementTypeTwo] = React.useState('')
  const [elementThree, setElementThree] = React.useState('')
  const [elementTypeThree, setElementTypeThree] = React.useState('')
  const [elementFour, setElementFour] = React.useState('')
  const [elementTypeFour, setElementTypeFour] = React.useState('')
  const [elementFive, setElementFive] = React.useState('')
  const [elementTypeFive, setElementTypeFive] = React.useState('')
  const [elementSix, setElementSix] = React.useState('')
  const [elementTypeSix, setElementTypeSix] = React.useState('')
  const [elementSeven, setElementSeven] = React.useState('')
  const [elementTypeSeven, setElementTypeSeven] = React.useState('')
  const [elementEight, setElementEight] = React.useState('')
  const [elementTypeEight, setElementTypeEight] = React.useState('')
  const [elementNine, setElementNine] = React.useState('')
  const [elementTypeNine, setElementTypeNine] = React.useState('')
  const [elementTen, setElementTen] = React.useState('')
  const [elementTypeTen, setElementTypeTen] = React.useState('')
  const [redirect, setRedirect] = React.useState(false)
  const [openModal, setOpenModal] = React.useState(false)

  async function getNotifications () {
    const response = await API.notification.all()
    response.notifications.forEach(element => {
      if (element.module==='certificate-ssl') {
        setCertificateSSLID(element.id)
        setCertificateSSL(element.sms)
      }
      if (element.module==='change-dns') {
        setChangeDnsID(element.id)
        setChangeDns(element.sms)
      }
      if (element.module==='infrastructure-exposure') {
        setInfrastructureExposureID(element.id)
        setInfrastructureExposure(element.sms)
      }
      if (element.module==='information-leak') {
        setInformationLeakID(element.id)
        setInformationLeak(element.sms)
      }
      if (element.module==='phishing') {
        setPhishingID(element.id)
        setPhishing(element.sms)
      }
    })
  }

  async function updateNotifications () {
    await API.notification.update({
      notifications: [
        {
          id: certificateSSLID,
          sms: certificateSSL
        },
        {
          id: changeDnsID,
          sms: changeDns
        },
        {
          id: infrastructureExposureID,
          sms: infrastructureExposure
        },
        {
          id: informationLeakID,
          sms: informationLeak
        },
        {
          id: phishingID,
          sms: phishing
        }
      ]
    })
    setMessageNotification('Actualizado exitosamente')
  }

  async function createElement () {
    await API.element.create({
      elements: [
        {
          reference: '1',
          type: elementTypeOne,
          value: elementOne
        },
        {
          reference: '2',
          type: elementTypeTwo,
          value: elementTwo
        },
        {
          reference: '3',
          type: elementTypeThree,
          value: elementThree
        },
        {
          reference: '4',
          type: elementTypeFour,
          value: elementFour
        },
        {
          reference: '5',
          type: elementTypeFive,
          value: elementFive
        },
        {
          reference: '6',
          type: elementTypeSix,
          value: elementSix
        },
        {
          reference: '7',
          type: elementTypeSeven,
          value: elementSeven
        },
        {
          reference: '8',
          type: elementTypeEight,
          value: elementEight
        },
        {
          reference: '9',
          type: elementTypeNine,
          value: elementNine
        },
        {
          reference: '10',
          type: elementTypeTen,
          value: elementTen
        }
      ]
    })
    setMessageElement('Actualizado exitosamente')
    setOpenModal(true)
  }

  async function getElements () {
    const response = await API.element.all()
    response.records.forEach(element => {
      if (element.reference==='1') {
        setElementTypeOne(element.type)
        setElementOne(element.value)
      }
      if (element.reference==='2') {
        setElementTypeTwo(element.type)
        setElementTwo(element.value)
      }
      if (element.reference==='3') {
        setElementTypeThree(element.type)
        setElementThree(element.value)
      }
      if (element.reference==='4') {
        setElementTypeFour(element.type)
        setElementFour(element.value)
      }
      if (element.reference==='5') {
        setElementTypeFive(element.type)
        setElementFive(element.value)
      }
      if (element.reference==='6') {
        setElementTypeSix(element.type)
        setElementSix(element.value)
      }
      if (element.reference==='7') {
        setElementTypeSeven(element.type)
        setElementSeven(element.value)
      }
      if (element.reference==='8') {
        setElementTypeEight(element.type)
        setElementEight(element.value)
      }
      if (element.reference==='9') {
        setElementTypeNine(element.type)
        setElementNine(element.value)
      }
      if (element.reference==='10') {
        setElementTypeTen(element.type)
        setElementTen(element.value)
      }
    })
  }

  React.useEffect(() => {
    getNotifications()
    getElements()
  }, [])

  function handleElementOne (event) {
    setElementOne({
      ...elementOne,
      [event.target.name]: event.target.value
    })
  }

  function handleElementTwo (event) {
    setElementTwo({
      ...elementTwo,
      [event.target.name]: event.target.value
    })
  }

  function handleElementThree (event) {
    setElementThree({
      ...elementThree,
      [event.target.name]: event.target.value
    })
  }

  function handleElementFour (event) {
    setElementFour({
      ...elementFour,
      [event.target.name]: event.target.value
    })
  }

  function handleElementFive (event) {
    setElementFive({
      ...elementFive,
      [event.target.name]: event.target.value
    })
  }

  function handleElementSix (event) {
    setElementSix({
      ...elementSix,
      [event.target.name]: event.target.value
    })
  }

  function handleElementSeven (event) {
    setElementSeven({
      ...elementSeven,
      [event.target.name]: event.target.value
    })
  }

  function handleElementEight (event) {
    setElementEight({
      ...elementEight,
      [event.target.name]: event.target.value
    })
  }

  function handleElementNine (event) {
    setElementNine({
      ...elementNine,
      [event.target.name]: event.target.value
    })
  }

  function handleElementTen (event) {
    setElementTen({
      ...elementTen,
      [event.target.name]: event.target.value
    })
  }

  if (redirect) {
    return (
      <Redirect to={redirect} />
    )
  }


  return (
    <div className='layout'>
      <Nav item='home' />
      <div className='main-content'>
        <Header />
        <div className='header bg-gradient-info pb-8 pt-5 pt-md-8'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-xl-4 order-xl-2 mb-5 mb-xl-0'>
                <div className='card bg-secondary shadow'>
                  <div className='card-header bg-white border-0'>
                    <div className='row align-items-center'>
                      <div className='col-8'>
                        <h3 className='mb-0'>Notificaciones</h3>
                        {messageNotification && (
                          <span className='badge badge-pill badge-success'>{messageNotification}</span>
                        )}
                      </div>
                      <div className='col-4 text-right'>
                        <button onClick={() => updateNotifications()} className='btn btn-sm btn-primary'>Actualizar</button>
                      </div>
                    </div>
                  </div>
                  <div className='card-body'>
                  <h6 className='heading-small text-muted mb-4'>Notificaciones SMS</h6>
                    <div className='pl-lg-4'>
                      <div className='row'>
                        <div className='col-lg-6'>
                          <label className='form-control-label'>Suplantación</label>
                        </div>
                        <div className='col-lg-6'>
                          <label className='custom-toggle'>
                            <input type='checkbox' checked={phishing} onClick={() => setPhishing(!phishing)} />
                            <span className='custom-toggle-slider rounded-circle'></span>
                          </label>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-lg-6'>
                          <label className='form-control-label'>Fuga de información</label>
                        </div>
                        <div className='col-lg-6'>
                          <label className='custom-toggle'>
                            <input type='checkbox' checked={informationLeak} onClick={() => setInformationLeak(!informationLeak)} />
                            <span className='custom-toggle-slider rounded-circle'></span>
                          </label>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-lg-6'>
                          <label className='form-control-label'>Exposición infraestructura</label>
                        </div>
                        <div className='col-lg-6'>
                          <label className='custom-toggle'>
                            <input type='checkbox' checked={infrastructureExposure} onClick={() => setInfrastructureExposure(!infrastructureExposure)} />
                            <span className='custom-toggle-slider rounded-circle'></span>
                          </label>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-lg-6'>
                          <label className='form-control-label'>Cambios de DNS</label>
                        </div>
                        <div className='col-lg-6'>
                          <label className='custom-toggle'>
                            <input type='checkbox' checked={changeDns} onClick={() => setChangeDns(!changeDns)} />
                            <span className='custom-toggle-slider rounded-circle'></span>
                          </label>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-lg-6'>
                          <label className='form-control-label'>Certificados de SSL</label>
                        </div>
                        <div className='col-lg-6'>
                          <label className='custom-toggle'>
                            <input type='checkbox' checked={certificateSSL} onClick={() => setCertificateSSL(!certificateSSL)} />
                            <span className='custom-toggle-slider rounded-circle'></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <hr className='my-4' />
                  </div>
                </div>
              </div>
              <div className='col-xl-8 order-xl-1'>
                <div className='card bg-secondary shadow'>
                  <div className='card-header bg-white border-0'>
                    <div className='row align-items-center'>
                      <div className='col-8'>
                        <h3 className='mb-0'>Elementos de información</h3>
                        {messageElement && (
                          <span className='badge badge-pill badge-success'>{messageElement}</span>
                        )}
                      </div>
                      <div className='col-4 text-right'>
                        <button onClick={() => createElement()} className='btn btn-sm btn-primary'>Guardar elementos</button>
                      </div>
                    </div>
                  </div>
                  <div className='card-body'>
                    <h6 className='heading-small text-muted mb-4'>Elementos de información</h6>
                    <div className='pl-lg-4'>
                      <div className='row'>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label'>Tipo</label>
                            <select
                              className='form-control form-control-alternative'
                              value={elementTypeOne}
                              onChange={(event) => {
                                setElementTypeOne(event.target.value)
                                setElementOne('')
                              }}
                            >
                              <option value=''>Seleccione un tipo</option>
                              <option value='Nombre de dominio'>Nombre de dominio</option>
                              <option value='Persona'>Persona</option>
                              <option value='Hash'>Hash</option>
                            </select>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          {(elementTypeOne === 'Persona') ? (
                            <div className='row'>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Nombre completo</label>
                                  <input
                                    type='text'
                                    name="fullName"
                                    className='form-control form-control-alternative'
                                    value={elementOne.fullName}
                                    onChange={(event) => handleElementOne(event)}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Cédula</label>
                                  <input
                                    type='text'
                                    className='form-control form-control-alternative'
                                    name='num_identification'
                                    value={elementOne.num_identification}
                                    onChange={(event) => handleElementOne(event)}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Correo electrónico</label>
                                  <input
                                    type='email'
                                    className='form-control form-control-alternative'
                                    name='email'
                                    value={elementOne.email}
                                    onChange={(event) => handleElementOne(event)}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Celular</label>
                                  <input
                                    type='text'
                                    className='form-control form-control-alternative'
                                    name='phone'
                                    value={elementOne.phone}
                                    onChange={(event) => handleElementOne(event)}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className='form-group'>
                              <label className='form-control-label'>Valor</label>
                              <input
                                type='text'
                                className='form-control form-control-alternative'
                                value={elementOne}
                                onChange={(event) => setElementOne(event.target.value)}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <hr className='my-4' />
                      <div className='row'>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label'>Tipo</label>
                            <select
                              className='form-control form-control-alternative'
                              value={elementTypeTwo}
                              onChange={(event) => {
                                setElementTypeTwo(event.target.value)
                                setElementTwo('')
                              }}
                            >
                              <option value=''>Seleccione un tipo</option>
                              <option value='Nombre de dominio'>Nombre de dominio</option>
                              <option value='Persona'>Persona</option>
                              <option value='Hash'>Hash</option>
                            </select>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                        {(elementTypeTwo === 'Persona') ? (
                            <div className='row'>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Nombre completo</label>
                                  <input
                                    type='text'
                                    name="fullName"
                                    className='form-control form-control-alternative'
                                    value={elementTwo.fullName}
                                    onChange={(event) => handleElementTwo(event)}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Cédula</label>
                                  <input
                                    type='text'
                                    className='form-control form-control-alternative'
                                    name='num_identification'
                                    value={elementTwo.num_identification}
                                    onChange={(event) => handleElementTwo(event)}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Correo electrónico</label>
                                  <input
                                    type='email'
                                    className='form-control form-control-alternative'
                                    name='email'
                                    value={elementTwo.email}
                                    onChange={(event) => handleElementTwo(event)}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Celular</label>
                                  <input
                                    type='text'
                                    className='form-control form-control-alternative'
                                    name='phone'
                                    value={elementTwo.phone}
                                    onChange={(event) => handleElementTwo(event)}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className='form-group'>
                              <label className='form-control-label'>Valor</label>
                              <input
                                type='text'
                                className='form-control form-control-alternative'
                                value={elementTwo}
                                onChange={(event) => setElementTwo(event.target.value)}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <hr className='my-4' />
                      <div className='row'>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label'>Tipo</label>
                            <select
                              className='form-control form-control-alternative'
                              value={elementTypeThree}
                              onChange={(event) => {
                                setElementTypeThree(event.target.value)
                                setElementThree('')
                              }}
                            >
                              <option value=''>Seleccione un tipo</option>
                              <option value='Nombre de dominio'>Nombre de dominio</option>
                              <option value='Persona'>Persona</option>
                              <option value='Hash'>Hash</option>
                            </select>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                        {(elementTypeThree === 'Persona') ? (
                            <div className='row'>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Nombre completo</label>
                                  <input
                                    type='text'
                                    name="fullName"
                                    className='form-control form-control-alternative'
                                    value={elementThree.fullName}
                                    onChange={(event) => handleElementThree(event)}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Cédula</label>
                                  <input
                                    type='text'
                                    className='form-control form-control-alternative'
                                    name='num_identification'
                                    value={elementThree.num_identification}
                                    onChange={(event) => handleElementThree(event)}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Correo electrónico</label>
                                  <input
                                    type='email'
                                    className='form-control form-control-alternative'
                                    name='email'
                                    value={elementThree.email}
                                    onChange={(event) => handleElementThree(event)}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Celular</label>
                                  <input
                                    type='text'
                                    className='form-control form-control-alternative'
                                    name='phone'
                                    value={elementThree.phone}
                                    onChange={(event) => handleElementThree(event)}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className='form-group'>
                              <label className='form-control-label'>Valor</label>
                              <input
                                type='text'
                                className='form-control form-control-alternative'
                                value={elementThree}
                                onChange={(event) => setElementThree(event.target.value)}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <hr className='my-4' />
                      <div className='row'>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label'>Tipo</label>
                            <select
                              className='form-control form-control-alternative'
                              value={elementTypeFour}
                              onChange={(event) => {
                                setElementTypeFour(event.target.value)
                                setElementFour('')
                              }}
                            >
                              <option value=''>Seleccione un tipo</option>
                              <option value='Nombre de dominio'>Nombre de dominio</option>
                              <option value='Persona'>Persona</option>
                              <option value='Hash'>Hash</option>
                            </select>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                        {(elementTypeFour === 'Persona') ? (
                            <div className='row'>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Nombre completo</label>
                                  <input
                                    type='text'
                                    name="fullName"
                                    className='form-control form-control-alternative'
                                    value={elementFour.fullName}
                                    onChange={(event) => handleElementFour(event)}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Cédula</label>
                                  <input
                                    type='text'
                                    className='form-control form-control-alternative'
                                    name='num_identification'
                                    value={elementFour.num_identification}
                                    onChange={(event) => handleElementFour(event)}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Correo electrónico</label>
                                  <input
                                    type='email'
                                    className='form-control form-control-alternative'
                                    name='email'
                                    value={elementFour.email}
                                    onChange={(event) => handleElementFour(event)}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Celular</label>
                                  <input
                                    type='text'
                                    className='form-control form-control-alternative'
                                    name='phone'
                                    value={elementFour.phone}
                                    onChange={(event) => handleElementFour(event)}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className='form-group'>
                              <label className='form-control-label'>Valor</label>
                              <input
                                type='text'
                                className='form-control form-control-alternative'
                                value={elementFour}
                                onChange={(event) => setElementFour(event.target.value)}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <hr className='my-4' />
                      <div className='row'>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label'>Tipo</label>
                            <select
                              className='form-control form-control-alternative'
                              value={elementTypeFive}
                              onChange={(event) => {
                                setElementTypeFive(event.target.value)
                                setElementFive('')
                              }}
                            >
                              <option value=''>Seleccione un tipo</option>
                              <option value='Nombre de dominio'>Nombre de dominio</option>
                              <option value='Persona'>Persona</option>
                              <option value='Hash'>Hash</option>
                            </select>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                        {(elementTypeFive === 'Persona') ? (
                            <div className='row'>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Nombre completo</label>
                                  <input
                                    type='text'
                                    name="fullName"
                                    className='form-control form-control-alternative'
                                    value={elementFive.fullName}
                                    onChange={(event) => handleElementFive(event)}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Cédula</label>
                                  <input
                                    type='text'
                                    className='form-control form-control-alternative'
                                    name='num_identification'
                                    value={elementFive.num_identification}
                                    onChange={(event) => handleElementFive(event)}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Correo electrónico</label>
                                  <input
                                    type='email'
                                    className='form-control form-control-alternative'
                                    name='email'
                                    value={elementFive.email}
                                    onChange={(event) => handleElementFive(event)}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Celular</label>
                                  <input
                                    type='text'
                                    className='form-control form-control-alternative'
                                    name='phone'
                                    value={elementFive.phone}
                                    onChange={(event) => handleElementFive(event)}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className='form-group'>
                              <label className='form-control-label'>Valor</label>
                              <input
                                type='text'
                                className='form-control form-control-alternative'
                                value={elementFive}
                                onChange={(event) => setElementFive(event.target.value)}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <hr className='my-4' />
                      <div className='row'>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label'>Tipo</label>
                            <select
                              className='form-control form-control-alternative'
                              value={elementTypeSix}
                              onChange={(event) => {
                                setElementTypeSix(event.target.value)
                                setElementSix('')
                              }}
                            >
                              <option value=''>Seleccione un tipo</option>
                              <option value='Nombre de dominio'>Nombre de dominio</option>
                              <option value='Persona'>Persona</option>
                              <option value='Hash'>Hash</option>
                            </select>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                        {(elementTypeSix === 'Persona') ? (
                            <div className='row'>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Nombre completo</label>
                                  <input
                                    type='text'
                                    name="fullName"
                                    className='form-control form-control-alternative'
                                    value={elementSix.fullName}
                                    onChange={(event) => handleElementSix(event)}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Cédula</label>
                                  <input
                                    type='text'
                                    className='form-control form-control-alternative'
                                    name='num_identification'
                                    value={elementSix.num_identification}
                                    onChange={(event) => handleElementSix(event)}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Correo electrónico</label>
                                  <input
                                    type='email'
                                    className='form-control form-control-alternative'
                                    name='email'
                                    value={elementSix.email}
                                    onChange={(event) => handleElementSix(event)}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Celular</label>
                                  <input
                                    type='text'
                                    className='form-control form-control-alternative'
                                    name='phone'
                                    value={elementSix.phone}
                                    onChange={(event) => handleElementSix(event)}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className='form-group'>
                              <label className='form-control-label'>Valor</label>
                              <input
                                type='text'
                                className='form-control form-control-alternative'
                                value={elementSix}
                                onChange={(event) => setElementSix(event.target.value)}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <hr className='my-4' />
                      <div className='row'>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label'>Tipo</label>
                            <select
                              className='form-control form-control-alternative'
                              value={elementTypeSeven}
                              onChange={(event) => {
                                setElementTypeSeven(event.target.value)
                                setElementSeven('')
                              }}
                            >
                              <option value=''>Seleccione un tipo</option>
                              <option value='Nombre de dominio'>Nombre de dominio</option>
                              <option value='Persona'>Persona</option>
                              <option value='Hash'>Hash</option>
                            </select>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                        {(elementTypeSeven === 'Persona') ? (
                            <div className='row'>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Nombre completo</label>
                                  <input
                                    type='text'
                                    name="fullName"
                                    className='form-control form-control-alternative'
                                    value={elementSeven.fullName}
                                    onChange={(event) => handleElementSeven(event)}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Cédula</label>
                                  <input
                                    type='text'
                                    className='form-control form-control-alternative'
                                    name='num_identification'
                                    value={elementSeven.num_identification}
                                    onChange={(event) => handleElementSeven(event)}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Correo electrónico</label>
                                  <input
                                    type='email'
                                    className='form-control form-control-alternative'
                                    name='email'
                                    value={elementSeven.email}
                                    onChange={(event) => handleElementSeven(event)}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Celular</label>
                                  <input
                                    type='text'
                                    className='form-control form-control-alternative'
                                    name='phone'
                                    value={elementSeven.phone}
                                    onChange={(event) => handleElementSeven(event)}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className='form-group'>
                              <label className='form-control-label'>Valor</label>
                              <input
                                type='text'
                                className='form-control form-control-alternative'
                                value={elementSeven}
                                onChange={(event) => setElementSeven(event.target.value)}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <hr className='my-4' />
                      <div className='row'>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label'>Tipo</label>
                            <select
                              className='form-control form-control-alternative'
                              value={elementTypeEight}
                              onChange={(event) => {
                                setElementTypeEight(event.target.value)
                                setElementEight('')
                              }}
                            >
                              <option value=''>Seleccione un tipo</option>
                              <option value='Nombre de dominio'>Nombre de dominio</option>
                              <option value='Persona'>Persona</option>
                              <option value='Hash'>Hash</option>
                            </select>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                        {(elementTypeEight === 'Persona') ? (
                            <div className='row'>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Nombre completo</label>
                                  <input
                                    type='text'
                                    name="fullName"
                                    className='form-control form-control-alternative'
                                    value={elementEight.fullName}
                                    onChange={(event) => handleElementEight(event)}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Cédula</label>
                                  <input
                                    type='text'
                                    className='form-control form-control-alternative'
                                    name='num_identification'
                                    value={elementEight.num_identification}
                                    onChange={(event) => handleElementEight(event)}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Correo electrónico</label>
                                  <input
                                    type='email'
                                    className='form-control form-control-alternative'
                                    name='email'
                                    value={elementEight.email}
                                    onChange={(event) => handleElementEight(event)}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Celular</label>
                                  <input
                                    type='text'
                                    className='form-control form-control-alternative'
                                    name='phone'
                                    value={elementEight.phone}
                                    onChange={(event) => handleElementEight(event)}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className='form-group'>
                              <label className='form-control-label'>Valor</label>
                              <input
                                type='text'
                                className='form-control form-control-alternative'
                                value={elementEight}
                                onChange={(event) => setElementEight(event.target.value)}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <hr className='my-4' />
                      <div className='row'>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label'>Tipo</label>
                            <select
                              className='form-control form-control-alternative'
                              value={elementTypeNine}
                              onChange={(event) => {
                                setElementTypeNine(event.target.value)
                                setElementNine('')
                              }}
                            >
                              <option value=''>Seleccione un tipo</option>
                              <option value='Nombre de dominio'>Nombre de dominio</option>
                              <option value='Persona'>Persona</option>
                              <option value='Hash'>Hash</option>
                            </select>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                        {(elementTypeNine === 'Persona') ? (
                            <div className='row'>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Nombre completo</label>
                                  <input
                                    type='text'
                                    name="fullName"
                                    className='form-control form-control-alternative'
                                    value={elementNine.fullName}
                                    onChange={(event) => handleElementNine(event)}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Cédula</label>
                                  <input
                                    type='text'
                                    className='form-control form-control-alternative'
                                    name='num_identification'
                                    value={elementNine.num_identification}
                                    onChange={(event) => handleElementNine(event)}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Correo electrónico</label>
                                  <input
                                    type='email'
                                    className='form-control form-control-alternative'
                                    name='email'
                                    value={elementNine.email}
                                    onChange={(event) => handleElementNine(event)}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Celular</label>
                                  <input
                                    type='text'
                                    className='form-control form-control-alternative'
                                    name='phone'
                                    value={elementNine.phone}
                                    onChange={(event) => handleElementNine(event)}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className='form-group'>
                              <label className='form-control-label'>Valor</label>
                              <input
                                type='text'
                                className='form-control form-control-alternative'
                                value={elementNine}
                                onChange={(event) => setElementNine(event.target.value)}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <hr className='my-4' />
                      <div className='row'>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label'>Tipo</label>
                            <select
                              className='form-control form-control-alternative'
                              value={elementTypeTen}
                              onChange={(event) => {
                                setElementTypeTen(event.target.value)
                                setElementTen('')
                              }}
                            >
                              <option value=''>Seleccione un tipo</option>
                              <option value='Nombre de dominio'>Nombre de dominio</option>
                              <option value='Persona'>Persona</option>
                              <option value='Hash'>Hash</option>
                            </select>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                        {(elementTypeTen === 'Persona') ? (
                            <div className='row'>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Nombre completo</label>
                                  <input
                                    type='text'
                                    name="fullName"
                                    className='form-control form-control-alternative'
                                    value={elementTen.fullName}
                                    onChange={(event) => handleElementTen(event)}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Cédula</label>
                                  <input
                                    type='text'
                                    className='form-control form-control-alternative'
                                    name='num_identification'
                                    value={elementTen.num_identification}
                                    onChange={(event) => handleElementTen(event)}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Correo electrónico</label>
                                  <input
                                    type='email'
                                    className='form-control form-control-alternative'
                                    name='email'
                                    value={elementTen.email}
                                    onChange={(event) => handleElementTen(event)}
                                  />
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label className='form-control-label'>Celular</label>
                                  <input
                                    type='text'
                                    className='form-control form-control-alternative'
                                    name='phone'
                                    value={elementTen.phone}
                                    onChange={(event) => handleElementTen(event)}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className='form-group'>
                              <label className='form-control-label'>Valor</label>
                              <input
                                type='text'
                                className='form-control form-control-alternative'
                                value={elementTen}
                                onChange={(event) => setElementTen(event.target.value)}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <hr className='my-4' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className='modal-dialog-centered'
        isOpen={openModal}
        toggle={() => setRedirect('/home')}
      >
        <div className='modal-header'>
          <h4 className='modal-title text-center' id='save-configuration'>Elementos de información</h4>
          <button
            aria-label='Close'
            className='close'
            data-dismiss='modal'
            type='button'
            onClick={() => setRedirect('/home')}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className='modal-body'>
          <p>
              Los elementos de información fueron guardados correctamente
          </p>
        </div>
        <div className='modal-footer'>
          <Button
            className='ml-auto'
            color='link'
            data-dismiss='modal'
            type='button'
            onClick={() => setRedirect('/home')}
          >
            Cerrar
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default Configuration
