import React from 'react'
import { Link } from 'react-router-dom'
import {
  Container,
  Button
} from 'reactstrap'
import Nav from '../Nav'
import API from '../../api/index'
import FileBase64 from 'react-file-base64'

const UploadCreate = () => {
  const [description, setDescription] = React.useState('')
  const [descriptionMessage, setDescriptionMessage] = React.useState('')
  const [url, setURL] = React.useState('')
  const [urlMessage, setURLMessage] = React.useState('')
  const [messageSuccess, setMessageSuccess] = React.useState('')

  function clearMessage() {
    setURLMessage('')
    setDescriptionMessage('')
  }

  async function handleCreateUpload() {
    clearMessage()
    const response = await API.upload.create({
      description: description,
      file_upload: url
    })
    if (response.ok) {
      setMessageSuccess(response.message)
      setURL('')
      setDescription('')
    } else if (response.message === 'No es formato JSON') {
      setURLMessage('El campo es obligatorio')
      setDescriptionMessage('El campo es obligatorio')
    } else {
      if (response.errors.description) {
        setDescriptionMessage(response.errors.description[0])
      }
      if (response.errors.file_upload) {
        setURLMessage(response.errors.file_upload[0])
      }
    }
  }

  function getFiles(file) {
    setURLMessage('')
    setURL({'name': file.name, 'base64': file.base64})
  }

  return (
    <div className='layout'>
        <Nav item='phishing' />
        <div className='main-content'>
          <div className='header bg-gradient-info pb-8 pt-5 pt-md-6'>
            <Container fluid>
              <Button className='btn-icon btn-3' color='default' type='button'>
                <span className='btn-inner--icon'>
                  <i className='ni ni-bold-left' />
                </span>
                <Link className='btn-inner--text text-white' to='/upload'>Volver</Link>
              </Button>
            </Container>
          </div>
          <Container className='mt--7' fluid>
            <div className='content-auto'>
              <div className='card bg-secondary shadow'>
                <div className='card-header bg-white border-0'>
                  <div className='row align-items-center'>
                    <div className='col-8'>
                      <h3 className='mb-0'>Nuevo archivo</h3>
                      {messageSuccess && (
                        <span className='badge badge-pill badge-success'>{messageSuccess}</span>
                      )}
                    </div>
                    <div className='col-4 text-right'>
                      <span className='btn btn-sm btn-primary text-white' onClick={handleCreateUpload}>Crear archivo</span>
                    </div>
                  </div>
                </div>
                <div className='card-body'>
                  <form>
                    <h6 className='heading-small text-muted mb-4'>Nuevo documento</h6>
                      <div className='pl-lg-4'>
                        <div className='row'>
                          <div className='col-lg-12'>
                            <div className='form-group'>
                              <label className='form-control-label'>Descripción</label>
                              <textarea
                                className='form-control form-control-alternative'
                                rows='5'
                                name='description'
                                value={description}
                                onChange={(event) => {
                                  setDescription(event.target.value)
                                  setDescriptionMessage('')
                                }}
                              />
                              {descriptionMessage && (
                                <span className='badge badge-pill badge-danger'>{descriptionMessage}</span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='form-group'>
                            <label className='form-control-label'>Archivo</label>
                            <div
                              className='form-control form-control-alternative'
                            >
                              {url ? url.name: <FileBase64 onDone={getFiles} />}
                            </div>
                            {urlMessage && (
                                <span className='badge badge-pill badge-danger'>{urlMessage}</span>
                              )}
                          </div>
                        </div>
                      </div>
                  </form>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
  )

}

export default UploadCreate
