import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Row,
  Col,
  Button
} from 'reactstrap'
import Logo from '../assets/img/cattleya.png'
import API from '../api/index'
import { getItem } from '../api/utils'
import '../assets/css/login.css'

const Verify2FA = (props) => {
  const [token, setToken] = React.useState('')
  const [tokenNumber, setTokenNumber] = React.useState('')
  const [messageError, setMessageError] = React.useState('')
  const [messageSuccess, setMessageSuccess] = React.useState('')
  const [redirect, setRedirect] = React.useState('')

  React.useEffect(() => {
    const token2FA = getItem('token2FA')
    if (token2FA) {
      setToken(token2FA)
    } else {
      setRedirect('/')
    }
  }, [])

  async function onSubmit() {
    setMessageError('')
    if (tokenNumber) {
      const response = await API.auth.verify2fa(token, tokenNumber)
      if (response.ok) {
        setRedirect('/home')
      } else {
        setMessageError('Código de seguridad incorrecto, intentalo nuevamente')
      }
    } else {
      setMessageError('El código de seguridad es obligatorio')
    }
  }

  async function resend2FA () {
    await API.auth.resend_2fa(token)
    setMessageSuccess('El código de seguridad fue enviado nuevamente.')
  }

  if (redirect) {
    return (<Redirect to={redirect} />)
  }
  
  return (
    <div className='center-block-login container-login'>
      <div style={{ minWidth: '415px' }}>
        <div className='text-center'>
          <a href='/' className='login-title'>
            <img src={Logo} alt='Cattleya' width='200' />
          </a>
          <p className='gray'>Autenticación de dos factores</p>
          <p>Ingrese el código de seguridad enviado al número de celular</p>
        </div>
        {messageError && (
          <div className='alert alert-danger' role='alert'>
            <strong>{messageError}</strong>
          </div>
        )}
        {messageSuccess && (
          <div className='alert alert-success' role='alert'>
            <strong>{messageSuccess}</strong>
          </div>
        )}
        <div>
          <Row>
            <Col>
              <div className='input-group input-group-alternative'>
                <div className='input-group-prepend'>
                  <span className='input-group-text'>
                    <i className='ni ni-lock-circle-open' />
                  </span>
                </div>
                <input
                  className='form-control'
                  placeholder='Código de seguridad'
                  type='password'
                  name='tokenNumber'
                  onChange={(event) => {
                    setMessageError('')
                    setMessageSuccess('')
                    setTokenNumber(event.target.value)
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      onSubmit()
                    }
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col>
              <Button style={{
                backgroundColor: '#BB2180',
                borderColor: '#BB2180',
                color: 'white'
              }} size='md' type='button' onClick={() => onSubmit()}>
                Verificar código
              </Button>
            </Col>
            <Col className='text-right' style={{fontSize: '14px'}}>
              ¿No recibiste el código?
              <span
                className='badge badge-info'
                onClick={() => resend2FA()}
                style={{
                  cursor: 'pointer'
                }}
              >
                Reenviar código
              </span>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default Verify2FA
